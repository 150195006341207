import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { logout, setTwoFactorValidated } from "../slices/auth";
import { useDispatch } from "react-redux";
import { useGet2FAVerifyMutation } from '../services/user';
import toast from 'react-hot-toast';

import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

export default function OtpVerify() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
    localStorage.removeItem("kniteToken");
    navigate("/login");
  }, [dispatch, navigate]);



  const [VerifyTwoFactor, { pin }] = useGet2FAVerifyMutation();

  const handleCodeCheck = async (formValue) => {
    try {
      const { pin } = formValue;
      const res = await VerifyTwoFactor({ pin }).unwrap();
      if (res === "Valid") {
        toast.success("2FA validated!");
        dispatch(setTwoFactorValidated(true));
        navigate("/");

      } else {
        toast.error("PIN is not correct! Try again.");
      }

    }
    catch (e) {
      console.log("Error", e)
    }
  }

  const initialValues = {
    pin: "",
  };

  const validationSchema = Yup.object().shape({
    pin: Yup.string().required("This field is required!"),
  });



  return (
    <>
      <div className="vh-100 d-flex justify-content-center">
        <div className="form-access my-auto">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleCodeCheck}
          >
            <Form>

              <span className="mb-0">OTP Verification</span>
              <p className="text-center mb-4">
                One time code from Authenticator
              </p>
              <Field name="pin" type="text" className="form-control" placeholder="Enter code here" />
              <div className="form-row">
                <div className="col-md-9">
                  <button type="submit" className="btn btn-primary">
                    Validate
                  </button>
                </div>
                <div className="col-md-3">
                  <button onClick={logOut} id="btnExit" className="btn btn-secondary">
                    Exit
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
}
