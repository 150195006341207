import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useWebSocket from 'react-use-websocket';
import BigNumber from 'bignumber.js';
import { Tabs, Tab } from 'react-bootstrap';
import Loading from './Loading';
import Table from './Table';
import { binanceApi } from '../services/binance';
import { kucoinApi } from '../services/kucoin';
import { therocktradingApi } from '../services/therocktrading';
import { useGetPairsQuery, useAddPairMutation, useDeletePairMutation } from '../services/user';
import { selectExchanges, getExchangeMessage } from '../slices/exchanges';
import { generateId } from '../helpers/utils';

export default function MarketsList() {
  const dispatch = useDispatch();
  const exchanges = useSelector(selectExchanges);
  const { data: subscribedPairs = [], isLoading: subscribedPairsIsLoading, isSuccess: subscribedPairsIsSuccess } = useGetPairsQuery();
  const [addPair] = useAddPairMutation();
  const [deletePair] = useDeletePairMutation();
  const favoritePairs = {};

  const marketColumns = [
    {
      header: "Pair",
      key: "symbol",
      content: (props) => {
        return (
          <>
            <i className={`icon ion-md-star ${props.row.favorite ? "star-active" : ""}`} onClick={(e) => {
              props.row.favorite ? deletePair(props.row.pairId) : addPair({
                "exchange": props.row.exchange,
                "exchange_id": props.row.exchangeId,
                "pair": props.row.symbol,
                "base_asset": props.row.baseAsset,
                "quote_asset": props.row.quoteAsset
              })
              console.log(e);
            }}></i> {props.value} [{props.row.exchange}]
          </>
        )
      }
    },
    {
      header: "Last Price",
      key: "lastPrice",
      content: (props) => new BigNumber(props.value).toFormat(null, 1)
    },
    {
      header: "Change (24H)",
      key: "change",
      className: (props) => {
        return props.value >= 0 ? "green" : "red"
      },
      content: (props) => new BigNumber(props.value).toFormat(null, 1)
    },
    {
      header: "High (24H)",
      key: "high",
      content: (props) => new BigNumber(props.value).toFormat(null, 1)
    },
    {
      header: "Low (24h)",
      key: "low",
      content: (props) => new BigNumber(props.value).toFormat(null, 1)
    },
    {
      header: "Volume (24h)",
      key: "volume",
      content: (props) => new BigNumber(props.value).toFormat(null, 1)
    },
  ];

  const binanceWebsocket = useWebSocket(exchanges.binance.url, {
    share: true,
    filter: (messageEvent) => {
      const message = JSON.parse(messageEvent.data);
      return getExchangeMessage.binance.symbols.messageFilter(message);
    }
  });

  const binanceSendJsonMessage = binanceWebsocket.sendJsonMessage;

  useEffect(() => {
    const subscribePayload = getExchangeMessage.binance.symbols.subscribePayload(generateId(), [getExchangeMessage.binance.symbols.channel()]);
    const unSubscribePayload = getExchangeMessage.binance.symbols.unsubscribePayload(generateId(), [getExchangeMessage.binance.symbols.channel()]);
    binanceSendJsonMessage(subscribePayload);
    return () => {
      binanceSendJsonMessage(unSubscribePayload);
    }
  }, [binanceSendJsonMessage]);

  const { data: binanceMarkets, isLoading: binanceIsLoading, isSuccess: binanceIsSuccess } = binanceApi.useGetAllTickersQuery({}, {
    keepUnusedDataFor: 1000,
    pollingInterval: 1000000,
  });
  const binanceMarketsUpdated = { ...binanceMarkets };

  if (binanceIsSuccess && binanceWebsocket.lastJsonMessage !== "") {
    const binanceMarketUpdates = getExchangeMessage.binance.symbols.messageFormatter(binanceWebsocket.lastJsonMessage);
    binanceMarketUpdates.forEach((update) => {
      let original = { ...binanceMarketsUpdated[update.symbol] };
      binanceMarketsUpdated[update.symbol] = Object.assign(original, update);
    });
    dispatch(
      binanceApi.util.updateQueryData('getAllTickers', undefined, (allTickers) => {
        allTickers = binanceMarketsUpdated;
      })
    )
  }

  if (subscribedPairsIsSuccess && binanceIsSuccess) {
    subscribedPairs.forEach((pair) => {
      if (pair.exchange.toLowerCase() === "binance" && pair.pair in binanceMarketsUpdated) {
        let original = { ...binanceMarketsUpdated[pair.pair] };
        let favorite = {
          pairId: pair.id,
          favorite: true
        };
        binanceMarketsUpdated[pair.pair] = Object.assign(original, favorite);
        favoritePairs[pair.pair] = binanceMarketsUpdated[pair.pair];
      }
    })
  }

  const { data: kucoinMarkets, isLoading: kucoinIsLoading, isSuccess: kucoinIsSuccess } = kucoinApi.useGetAllTickersQuery({}, {
    pollingInterval: 1000,
  });
  const kucoinMarketsUpdated = { ...kucoinMarkets };

  if (subscribedPairsIsSuccess && kucoinIsSuccess) {
    subscribedPairs.forEach((pair) => {
      if (pair.exchange.toLowerCase() === "kucoin" && pair.pair in kucoinMarketsUpdated) {
        let original = { ...kucoinMarketsUpdated[pair.pair] };
        let favorite = {
          pairId: pair.id,
          favorite: true
        };
        kucoinMarketsUpdated[pair.pair] = Object.assign(original, favorite);
        favoritePairs[pair.pair] = kucoinMarketsUpdated[pair.pair];
      }
    })
  }
  const { data: therocktradingMarkets, isLoading: therocktradingIsLoading, isSuccess: therocktradingIsSuccess } = therocktradingApi.useGetAllTickersQuery({}, {
    pollingInterval: 1000,
  });
  const therocktradingMarketsUpdated = { ...therocktradingMarkets };

  if (subscribedPairsIsSuccess && therocktradingIsSuccess) {
    subscribedPairs.forEach((pair) => {
      if (pair.exchange.toLowerCase() === "therocktrading" && pair.pair in therocktradingMarketsUpdated) {
        let original = { ...therocktradingMarketsUpdated[pair.pair] };
        let favorite = {
          pairId: pair.id,
          favorite: true
        };
        therocktradingMarketsUpdated[pair.pair] = Object.assign(original, favorite);
        favoritePairs[pair.pair] = therocktradingMarketsUpdated[pair.pair];
      }
    })
  }

  if (subscribedPairsIsLoading) {
    return <Loading />
  }

  subscribedPairs.forEach((pair) => {
    if (!(pair.pair in favoritePairs)) {
      favoritePairs[pair.pair] = {
        exchange: pair.exchange,
        exchangeId: pair.exchange_id,
        symbol: pair.pair,
        baseAsset: pair.base_asset,
        quoteAsset: pair.quote_asset,
        lastPrice: 0,
        change: 0,
        high: 0,
        low: 0,
        volume: 0,
        favorite: true,
        pairId: pair.id
      };
    }
  })


  return (
    <>
      <div className="markets pb70">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="markets-pair-list">
                <Tabs defaultActiveKey="favs">
                  <Tab eventKey="favs" title="Favorites">
                    {binanceIsLoading || kucoinIsLoading || therocktradingIsLoading ? <Loading /> : (
                      <Table columns={marketColumns} data={Object.values(favoritePairs)} />
                    )}
                  </Tab>
                  <Tab eventKey="bnb" title="Binance">
                    {binanceIsLoading ? <Loading /> : (
                      <Table columns={marketColumns} data={Object.values(binanceMarketsUpdated)} />
                    )}
                  </Tab>
                  <Tab eventKey="kcs" title="KuCoin">
                    {kucoinIsLoading ? <Loading /> : (
                      <Table columns={marketColumns} data={Object.values(kucoinMarketsUpdated)} />
                    )}
                  </Tab>
                  <Tab eventKey="trt" title="TheRockTrading">
                    {therocktradingIsLoading ? <Loading /> : (
                      <Table columns={marketColumns} data={Object.values(therocktradingMarketsUpdated)} />
                    )}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

