import React, { useEffect } from 'react';
import Header from '../components/Header';
import { selectUser } from "../slices/auth";
import { useSelector, useDispatch } from "react-redux";

import { Tab, Row, Col, Nav, Modal } from 'react-bootstrap';
import { useGetProfileQuery, useUpdateProfileMutation, useGet2FACodeQuery, useGet2FAVerifyMutation } from '../services/user';

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loading from '../components/Loading';
import toast from 'react-hot-toast';
import QRCode from "qrcode.react";

export default function Profile() {

  const dispatch = useDispatch();

  const { data: user, isLoading } = useGetProfileQuery();
  const [isOpenTFModal, setIsOpenTFModal] = React.useState(false);
  const [isOpenRTFModal, setIsOpenRTFModal] = React.useState(false);
  const [isEnabled2FA, setisEnabled2FA] = React.useState(false);
  let userState = useSelector(selectUser);

  useEffect(() => {
    setisEnabled2FA(userState.twofactor);
  }, [])

  const showTFModal = () => { setIsOpenTFModal(true); };
  const hideTFModal = () => { setIsOpenTFModal(false); };

  const showRTFModal = () => { setIsOpenRTFModal(true); };
  const hideRTFModal = () => { setIsOpenRTFModal(false); };

  const { data: qrSecret } = useGet2FACodeQuery();

  let wait = new URLSearchParams(qrSecret).get("secret")
  let secretQrCode = wait


  const [VerifyTwoFactor, { pin }] = useGet2FAVerifyMutation();
  const [PatchProfile, { fields }] = useUpdateProfileMutation();

  const handleVerify = async (formValue) => {
    try {
      const { pin } = formValue;
      const res = await VerifyTwoFactor({ pin }).unwrap();
      if (res === "Valid") {
        const two_factor_authentication = true
        await PatchProfile({ two_factor_authentication }).unwrap();
        toast.success("2FA activated successfully!");
        setIsOpenTFModal(false);
        dispatch(selectUser({ twofactor: true }));
        setisEnabled2FA(true);
      } else {
        toast.error("PIN is not correct! Try again.");
      }
    }
    catch (e) {
      console.log("Error", e)
    }
  }

  const handleVerifyRTF = async (formValue) => {
    try {
      const { pin } = formValue;
      const res = await VerifyTwoFactor({ pin }).unwrap();
      if (res === "Valid") {
        const two_factor_authentication = false
        await PatchProfile({ two_factor_authentication }).unwrap();
        toast.success("2FA removed successfully!");
        setIsOpenRTFModal(false);
        setisEnabled2FA(false);
        dispatch(selectUser({ twofactor: false }));
      } else {
        toast.error("PIN is not correct! Try again.");
      }
    }
    catch (e) {
      console.log("Error", e)
    }
  }


  const handleUserUpdate = async (formValue) => {
    try {
      const { email, firstname, lastname } = formValue;
      const patchProfile = await PatchProfile({ email, firstname, lastname }).unwrap();
      if (patchProfile === "Updated") {
        toast.success("User updated");
        //devo cambiare nome/cognome dallo stato
        //dispatch(selectUser({ twofactor: true }));
      } else {
        toast.error("Operation failed");
      }
    }
    catch (e) {
      console.log("Error", e)
    }
  }


  const initialValues = {
    pin: "",
  };

  const validationSchema = Yup.object().shape({
    pin: Yup.string().required("This field is required!"),
  });

  const validationUserSchema = Yup.object().shape({
    email: Yup.string().required("This field is required!"),
    firstname: Yup.string().required("This field is required!"),
    lastname: Yup.string().required("This field is required!"),
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Header />
      {user && (

        <div className="settings mtb15">
          <div className="container-fluid">

            <Modal className="dar" size="lg" show={isOpenTFModal} onHide={hideTFModal}>
              <Modal.Header>
                <Modal.Title>Enable Two Factor Authentication</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='row'>
                  <div className='col-md-6 text-center'>
                    <p className="center">
                      <QRCode size="240" className="img-responsive" value={qrSecret} />
                    </p>
                    <br />
                    <p>
                      2FA Backup key</p><p>
                      <span className='form-control disabled'>{secretQrCode}</span>
                    </p>

                  </div>
                  <div className='col-md-6'>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleVerify}
                    >
                      <Form>

                        <h2>Scan QR-code</h2>
                        <p>Scan this QR-code with your authenticator.</p>
                        <p>
                          <ul>
                            <li>Open the Google Authenticator app on your device</li>
                            <li>Tap on the symbol in the bottom right corner to add a new code</li>
                            <li>Tap “Scan a QR code”</li>
                            <li>Scan the QR code that is open in your browser                    </li>
                          </ul>
                        </p>
                        <p>
                          <Field name="pin" type="text" className="form-control" placeholder="PIN" />
                        </p>
                        <p><input type="submit" className="btn btn-primary" ></input></p>
                      </Form>
                    </Formik>

                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className='btn btn-secondary' onClick={hideTFModal}>Cancel</button>
              </Modal.Footer>
            </Modal>

            <Modal show={isOpenRTFModal} onHide={hideRTFModal}>
              <Modal.Header>
                <Modal.Title>Remove Two Factor Authentication</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='row'>
                  <div className='col-md-12'>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleVerifyRTF}
                    >
                      <Form>
                        <h2>Security test</h2>
                        <p>
                          <ul>
                            <li>Confirm that you are authorized to remove two-factor authentication by providing a valid PIN</li>
                          </ul>
                        </p>
                        <p>
                          <Field name="pin" type="text" className="form-control" placeholder="PIN" />
                        </p>
                        <p align="center"><input type="submit" className="btn btn-primary" ></input></p>
                      </Form>
                    </Formik>

                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className='btn btn-secondary' onClick={hideRTFModal}>Cancel</button>
              </Modal.Footer>
            </Modal>

            <Tab.Container defaultActiveKey="profile">
              <Row>
                <Col lg={3}>
                  <Nav variant="pills" className="settings-nav">
                    <Nav.Item>
                      <Nav.Link eventKey="profile">Profile</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="settings">Settings</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col lg={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="profile" id="#profile">
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">General Information</h5>
                          <div className="settings-profile">
                            <Formik
                              initialValues={{ email: user.username, firstname: user.firstname, lastname: user.lastname }}
                              validationSchema={validationUserSchema}
                              onSubmit={handleUserUpdate}
                            >
                              <Form>
                                <div className="form-row mt-4">
                                  <div className="col-md-6">
                                    <label htmlFor="email">Email (used as your Username) {user.username}</label>
                                    <Field id="email" name="email" type="text" className="form-control" placeholder="Email as Username" />
                                    <ErrorMessage
                                      name="email"
                                      component="div"
                                      className="alert alert-danger"
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <label htmlFor="emailAddress"><i class="icon ion-md-lock"></i>&nbsp;Role</label>
                                    <input
                                      id="role"
                                      type="text"
                                      className="form-control" disabled
                                      defaultValue={user.role}></input>
                                  </div>
                                  <div className="col-md-6">
                                    <label htmlFor="firstname">First name</label>
                                    <Field id="firstname" name="firstname" type="text" className="form-control" placeholder="Firstname" />
                                    <ErrorMessage
                                      name="firstname"
                                      component="div"
                                      className="alert alert-danger"
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <label htmlFor="formLast">Last name</label>
                                    <Field id="lastname" name="lastname" type="text" className="form-control" placeholder="Lastname" />
                                    <ErrorMessage
                                      name="lastname"
                                      component="div"
                                      className="alert alert-danger"
                                    />
                                  </div>
                                  <div className="col-md-12">
                                    <input type="submit" value="Update" />
                                  </div>
                                </div>
                              </Form>
                            </Formik>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title">Security Information</h5>
                          <div className="settings-profile">
                            <form>
                              <div className="form-row">
                                <div className="col-md-6">
                                  <label htmlFor="currentPass">
                                    Current password
                                  </label>
                                  <input
                                    id="currentPass"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your password"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label htmlFor="newPass">New password</label>
                                  <input
                                    id="newPass"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter new password"
                                  />
                                </div>
                                {/*}                                
                                <div className="col-md-6">
                                  <label htmlFor="securityOne">
                                    Security questions #1
                                  </label>
                                  <select
                                    id="securityOne"
                                    className="custom-select"
                                  >
                                    <option defaultValue>Choose...</option>
                                    <option value="pet">
                                      What was the name of your first pet?
                                    </option>
                                    <option value="mother_name">
                                      What's your Mother's name?
                                    </option>
                                    <option value="school">
                                      What was the name of your first school?
                                    </option>
                                    <option value="custom">
                                      Write a "custom" answer
                                    </option>
                                  </select>
                                </div>
                                <div className="col-md-6">
                                  <label htmlFor="securityAnsOne">Answer</label>
                                  <input
                                    id="securityAnsOne"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your answer"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label htmlFor="securityTwo">
                                    Security questions #2
                                  </label>
                                  <select
                                    id="securityTwo"
                                    className="custom-select"
                                  >
                                    <option defaultValue>Choose...</option>
                                    <option value="pet">
                                      What was the name of your first pet?
                                    </option>
                                    <option value="mother_name">
                                      What's your Mother's name?
                                    </option>
                                    <option value="school">
                                      What was the name of your first school?
                                    </option>
                                    <option value="custom">
                                      Write a "custom" answer
                                    </option>
                                  </select>
                                </div>
                                <div className="col-md-6">
                                  <label htmlFor="securityAnsTwo">Answer</label>
                                  <input
                                    id="securityAnsTwo"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your answer"
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label htmlFor="securityThree">
                                    Security questions #3
                                  </label>
                                  <select
                                    id="securityThree"
                                    className="custom-select"
                                  >
                                    <option defaultValue>Choose...</option>
                                    <option value="pet">
                                      What was the name of your first pet?
                                    </option>
                                    <option value="mother_name">
                                      What's your Mother's name?
                                    </option>
                                    <option value="school">
                                      What was the name of your first school?
                                    </option>
                                    <option value="custom">
                                      Write a "custom" answer
                                    </option>
                                  </select>
                                </div>
                                <div className="col-md-6">
                                  <label htmlFor="securityFore">Answer</label>
                                  <input
                                    id="securityFore"
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter your answer"
                                  />
                                </div>
      */}
                                <div className="col-md-12">
                                  <input type="submit" value="Update" />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="settings" id="#settings">
                      <div className="card">
                        <div className="card-body">

                          <div className="wallet-history">
                            <table className='table' variant="Dark">
                              <thead>
                                <tr>
                                  <th style={{ "width": "80%" }}>Service</th>
                                  <th>Status</th>
                                  <th style={{ "text-align": "center" }}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td><p>2FA</p>
                                    <span style={{ "color": "#4f5966" }}>
                                      Two factor authentication service
                                    </span></td>
                                  <td>
                                    {isEnabled2FA === false ?
                                      (<i class="icon ion-md-close-circle-outline red" style={{ "font-size": "24px" }} ></i>) :
                                      (<i class="icon ion-md-checkmark-circle-outline green" style={{ "font-size": "24px" }}></i>)}
                                  </td>
                                  <td style={{ "text-align": "center" }}>
                                    <div className="custom-control">

                                      {isEnabled2FA === false ? (
                                        <input type="submit" className='btn btn-sm btn-primary' value="Enable" onClick={showTFModal}></input>
                                      )
                                        :
                                        (
                                          <input type="submit" className='btn btn-sm btn-secondary' value="Remove" onClick={showRTFModal}></input>
                                        )
                                      }
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td><p>Latest news</p>
                                    <span style={{ "color": "#4f5966" }}>
                                      Get the latest news</span></td>
                                  <td><i class="icon ion-md-close-circle-outline red" style={{ "font-size": "24px" }}></i></td>
                                  <td style={{ "text-align": "center" }}>
                                    <div className="custom-control">
                                      <input type="submit" className='btn btn-sm btn-primary' value="Enable"></input>
                                    </div></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                        </div>
                      </div>

                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      )
      }
    </>
  );
}
