import { createSlice } from '@reduxjs/toolkit'
import { authApi } from '../services/auth'
import { userApi } from '../services/user'

const jwt = require('jsonwebtoken');

const initialState = {
  user: {
    id: localStorage.getItem('kniteToken') ? jwt.decode(JSON.parse(localStorage.getItem('kniteToken')).token).userid : "",
    firstname: localStorage.getItem('kniteToken') ? jwt.decode(JSON.parse(localStorage.getItem('kniteToken')).token).firstname : "",
    lastname: localStorage.getItem('kniteToken') ? jwt.decode(JSON.parse(localStorage.getItem('kniteToken')).token).lastname : "",
    role: localStorage.getItem('kniteToken') ? jwt.decode(JSON.parse(localStorage.getItem('kniteToken')).token).role : "",
    latestnews: localStorage.getItem('kniteToken') ? jwt.decode(JSON.parse(localStorage.getItem('kniteToken')).token).latestnews : "",
    twofactor: localStorage.getItem('kniteToken') ? jwt.decode(JSON.parse(localStorage.getItem('kniteToken')).token).twofactor : "",
    twofactorvalidated: false,

  },
  isAuthenticated: localStorage.getItem('kniteToken') ? true : false
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logout: (state) => {
      state.user = {};
      state.isAuthenticated = false;
    },
    setTwoFactorValidated: (state, action) => {
      state.user.twofactorvalidated = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.user = {
        id: payload ? jwt.decode(payload.token).id : "",
        firstname: payload ? jwt.decode(payload.token).firstname : "",
        lastname: payload ? jwt.decode(payload.token).lastname : "",
        role: payload ? jwt.decode(payload.token).role : "",
        latestnews: payload ? jwt.decode(payload.token).latestnews : "",
        twofactor: payload ? jwt.decode(payload.token).twofactor : "",
        twofactorvalidated: false,
      }
      state.isAuthenticated = true
      localStorage.setItem('kniteToken', JSON.stringify(payload))
      return state;
    }).addMatcher(userApi.endpoints.refreshToken.matchFulfilled, (state, { payload }) => {
      localStorage.setItem('kniteToken', JSON.stringify(payload))

      return state;
    })
  }
})

export const { logout } = authSlice.actions;
export default authSlice.reducer;
export const selectUser = (state) => state.auth.user;
export const selectAuthenticated = (state) => state.auth.isAuthenticated;
export const { setTwoFactorValidated } = authSlice.actions;




