import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPairData } from '../../slices/pair';
import { therocktradingApi } from '../../services/therocktrading';
import Loading from '../Loading';
import BigNumber from 'bignumber.js';

export default function OrderBookTRT() {
  const pairData = useSelector(selectPairData);

  const { data: orderBook, isLoading } = therocktradingApi.useGetOrderBookQuery(pairData.base_asset + '-' + pairData.quote_asset, {
    pollingInterval: 3000,
  });

  if (isLoading) return <Loading />

  let bids = [];
  let asks = [];
  let numRowsBid = Math.min(18, orderBook.bids.length);
  let numRowsAsk = Math.min(18, orderBook.asks.length);
  let maxBid = BigNumber.maximum(...orderBook.bids.map(bid => bid[0]));
  let minAsk = BigNumber.minimum(...orderBook.asks.map(ask => ask[0]));
  let minBid = new BigNumber(maxBid).minus(BigNumber.minimum(...orderBook.bids.map(bid => bid[0])));
  let maxAsk = new BigNumber(minAsk).minus(BigNumber.maximum(...orderBook.asks.map(ask => ask[0])));
  let totalBidsAmount = new BigNumber(0);
  let totalAsksAmount = new BigNumber(0);
  for (var i = 0; i < numRowsBid; i++) {
    let bidWidth = new BigNumber(maxBid).minus(orderBook.bids[i][0]).div(minBid).multipliedBy(100).toFormat(0);
    totalBidsAmount = totalBidsAmount.plus(new BigNumber(orderBook.bids[i][1]));
    bids.push({
      key: "bid-" + i + "-" + orderBook.bids[i][0] + "-" + orderBook.bids[i][1],
      price: new BigNumber(orderBook.bids[i][0]).toFormat(null, 1),
      quantity: new BigNumber(orderBook.bids[i][1]).toFormat(null, 1),
      total: totalBidsAmount.toFormat(null, 1),
      width: bidWidth,
      style: ".bid-" + i + ":after {width:" + bidWidth + "%}",
      class: "bid-" + i
    });
  }
  for (var ii = 0; ii < numRowsAsk; ii++) {
    let askWidth = new BigNumber(minAsk).minus(orderBook.asks[ii][0]).div(maxAsk).multipliedBy(100).toFormat(0);
    totalAsksAmount = totalAsksAmount.plus(new BigNumber(orderBook.asks[ii][1]));
    asks.unshift({
      key: "ask-" + ii + "-" + orderBook.asks[ii][0] + "-" + orderBook.asks[ii][1],
      price: new BigNumber(orderBook.asks[ii][0]).toFormat(null, 1),
      quantity: new BigNumber(orderBook.asks[ii][1]).toFormat(null, 1),
      total: totalAsksAmount.toFormat(null, 1),
      width: askWidth,
      style: ".ask-" + ii + ":after {width:" + askWidth + "%} ",
      class: "ask-" + ii
    });
  }

  //{{width: `${new BigNumber(props.diff).minus(props.ba[0]).div(props.max).multipliedBy(100).toFormat(2)}%`}}

  return (
    <>
      {asks.map((ask) => (<style key={"style-" + ask.key}>{ask.style}</style>))}
      {bids.map((bid) => (<style key={"style-" + bid.key}>{bid.style}</style>))}
      <div className="order-book">
        <h2 className="heading">Order Book</h2>
        <table className="table" style={{ "marginBottom": "0px", "lineHeight": "1.3" }}>
          <thead>
            <tr>
              <th>Price({pairData.quote_asset})</th>
              <th>Amount({pairData.base_asset})</th>
              <th>Total({pairData.base_asset})</th>
            </tr>
          </thead>
          <tbody style={{ "height": "360px" }}>
            {asks.map((ask) => (
              <tr className={"red-bg " + ask.class} key={ask.key} id={ask.key}>
                <td className="red">{ask.price}</td>
                <td>{ask.quantity}</td>
                <td>{ask.total}</td>
              </tr>
            ))}
          </tbody>
          <tbody style={{ "height": "360px" }}>
            {bids.map((bid) => (
              <tr className={"green-bg " + bid.class} key={bid.key} id={bid.key}>
                <td className="green">{bid.price}</td>
                <td>{bid.quantity}</td>
                <td>{bid.total}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
