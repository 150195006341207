import React, { useState } from 'react';
import _ from 'lodash';

const Table = (props) => {
  const columns = props.columns;
  const rows = props.data || [];
  const [filter, setFilter] = useState("");
  const [orderBy, setOrderBy] = useState({ column: columns[0].key, direction: "asc" });

  const filteredRows = rows.filter((row) => {
    return row.symbol.toLowerCase().indexOf(filter.toLowerCase()) >= 0 || filter === "";
  });
  const orderedRows = _.orderBy(filteredRows, [orderBy.column], [orderBy.direction]);

  return (
    <>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text" id="inputGroup-sizing-sm">
            <i className="icon ion-md-search"></i>
          </span>
        </div>
        <input
          type="text"
          className="form-control"
          placeholder="Search"
          aria-describedby="inputGroup-sizing-sm"
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>

      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              {columns.map((column) => (
                <th style={{ cursor: "pointer" }} onClick={() => setOrderBy({ column: column.key, direction: orderBy.column === column.key && orderBy.direction === "asc" ? "desc" : "asc" })}>
                  {column.header}
                  <span>
                    {column.key === orderBy.column
                      ? orderBy.direction === "desc"
                        ? ' ⬇️'
                        : ' ⬆️'
                      : ' ↕️'}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {orderedRows.map((row, index) => (
              <tr key={row.exchange + "-" + index}>
                {columns.map((column) => (
                  <td className={"className" in column
                    ? typeof column.className === "function"
                      ? column.className({ value: row[column.key], row: row }) : column.className
                    : ""
                  }>
                    {column.className}
                    {(() => {
                      if ("content" in column) {
                        return column["content"]({ value: row[column.key], row: row });
                      }
                      else {
                        return row[column.key];
                      }
                    })()}
                  </td>
                ))}

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Table;