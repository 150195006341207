import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { Navbar } from 'react-bootstrap';
import { selectActiveOrder } from '../slices/order';
import {
  userApi,
  useGetSubOrdersQuery,
  useDeleteSubOrderMutation,
  useUpdateSubOrderMutation
} from '../services/user';
import toast from 'react-hot-toast';


export default function OrderDetails() {

  const activeOrder = useSelector(selectActiveOrder);
  const cOrder = activeOrder != null ? activeOrder : null
  const dispatch = useDispatch();
  const { data = [] } = useGetSubOrdersQuery(cOrder)
  const [DeleteSubOrder] = useDeleteSubOrderMutation();
  const [UpdateSubOrder] = useUpdateSubOrderMutation();

  const handleSubOrdersDelete = async (values) => {
    try {
      if (window.confirm("Are you sure to cancel order #" + values.order_id + "/" + values.id + "?")) {
        const delSubOrder = await DeleteSubOrder(JSON.stringify({ oid: values.order_id, soid: values.id })).unwrap();
        if (delSubOrder === "Deleted") {
          toast.success("Order #" + values.order_id + "/" + values.id + "cancelled");
        } else {
          toast.error("Operation failed");
        }
      }
    }
    catch (e) {
      console.log("Error", e)
    }
  }


  const handleSubOrdersUpdate = async (values) => {
    try {
      if (window.confirm("Are you sure to update order " + values.order_id + "/" + values.id + "?")) {
        const updSubOrder = await UpdateSubOrder(JSON.stringify({ oid: values.order_id, soid: values.id })).unwrap();
        if (updSubOrder === "Updated") {
          toast.success("Order " + values.order_id + "/" + values.id + " updated");
          dispatch(userApi.util.invalidateTags(['SubOrders']))
        } else {
          toast.error("Operation failed");
        }
      }
    }
    catch (e) {
      console.log("Error", e)
    }
  }

  return (
    <>
      <div className="card">
        <div className="card-title" style={{ "margin-bottom": "0px" }}>
          <Navbar bg="dark" variant="dark" expand="md">
            <Container fluid>
              <Navbar.Brand href="#home">Details of order {cOrder} </Navbar.Brand>
            </Container>
          </Navbar>
        </div>
        <div className="card-body">
          <div className="wallet-history" style={{ "height": "380px", "overflow-y": "auto" }}>
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Order ID</th>
                  <th>Created</th>
                  <th>Nickname</th>
                  <th>Exchange</th>
                  <th>Exchange Order ID</th>
                  <th>Asset</th>
                  <th>Order Type</th>
                  <th>Price</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((order, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{order.id}</td>
                    <td>{order.created}</td>
                    <td>{order.account_nickname}</td>
                    <td>{order.exchange}</td>
                    <td>{order.exchange_order_id}</td>
                    <td>{order.asset}</td>
                    <td>{order.type}</td>
                    <td>{order.price}</td>
                    <td>{order.amount}</td>
                    <td>{order.status}{order.error.length > 0 ? <i class="icon ion-md-information-circle-outline red" style={{ "font-size": "18px" }} title={order.error} ></i> : ""}</td>
                    <td>{order.status == "PENDING" ? <input className='btn btn-danger btn-sm' type="button" value="Cancel" onClick={() => handleSubOrdersDelete(order)}></input> : ""}&nbsp;
                      {order.status == "PENDING" ? <input className='btn btn-success btn-sm' type="button" value="Update" onClick={() => handleSubOrdersUpdate(order)}></input> : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  )
}