import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import toast from 'react-hot-toast'
const baseUrl = '/api/protected/user'

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl, prepareHeaders: (headers, { getState }) => {
      if (getState().auth.isAuthenticated) {
        const token = JSON.parse(localStorage.getItem('kniteToken')).token;
        var bearer = `Bearer ` + token
        headers.set('Authorization', bearer);
      }
      return headers;
    }
  }),
  tagTypes: ['Profile', 'Pair', 'Accounts', 'Account', 'AccountApiKeys', 'Order', 'SubOrders'],
  endpoints: (builder) => ({
    refreshToken: builder.mutation({
      query: () => ({
        url: '/refreshToken',
        method: 'PUT',
      })
    }),
    getProfile: builder.query({
      query: () => '/profile',
      providesTags: ['Profile'],
    }),
    updateProfile: builder.mutation({
      query: (body) => ({
        url: '/profile',
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['Profile'],
    }),
    getPairs: builder.query({
      query: () => '/pairs',
      providesTags: ['Pair'],
    }),
    addPair: builder.mutation({
      query: (body) => ({
        url: '/pairs',
        method: 'POST',
        body
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          toast.success('Favorite pair added');
        } catch (err) {
          toast.error('Error adding favorite pair');
        }
      },
      invalidatesTags: ['Pair'],
    }),
    deletePair: builder.mutation({
      query: (id) => ({
        url: `/pairs/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          toast.success('Favorite pair removed');
        } catch (err) {
          toast.error('Error removing favorite pair');
        }
      },
      invalidatesTags: ['Pair'],
    }),
    getAccounts: builder.query({
      query: () => '/accounts',

      providesTags: ['Accounts'],
    }),
    addAccount: builder.mutation({
      query: (jsonparams) => ({
        url: '/accounts',
        method: 'POST',
        body: jsonparams
      }),
      providesTags: ['Accounts'],
    }),
    updateAccount: builder.mutation({
      query: ({ aid, ...params }) => ({
        url: `/accounts/${aid}/patch`,
        method: 'PATCH',
        body: params
      }),
      invalidatesTags: ['Accounts'],
    }),
    deleteAccount: builder.mutation({
      query: (aid) => ({
        url: `/accounts/${aid}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Accounts'],
    }),
    getAccountsBalance: builder.query({
      query: () => '/accounts/balance',
      providesTags: ['Accounts'],
    }),
    getAccount: builder.query({
      query: (aid) => ({ url: `/accounts/${aid}` }),
      providesTags: ['Account'],
    }),
    getAccountBalance: builder.query({
      query: (aid) => ({ url: `/accounts/${aid}/balance` }),
      providesTags: ['Account'],
    }),
    getAccountLiveBalance: builder.query({
      query: (aid) => ({ url: `/accounts/${aid}/livebalance` }),
      providesTags: ['Account'],
    }),

    getAccountApiKeys: builder.query({
      query: (aid) => ({ url: `/accounts/${aid}/apikeys` }),
      providesTags: ['AccountApiKeys'],
    }),
    addAccountApiKeys: builder.mutation({
      query: (jsonparams) => ({
        url: `/accounts/${JSON.parse(jsonparams).aid}/apikeys`,
        method: 'POST',
        body: jsonparams
      }),
      providesTags: ['AccountApiKeys'],
    }),
    deleteAccountApiKeys: builder.mutation({
      query: (jsonparams) => ({
        url: `/accounts/${JSON.parse(jsonparams).aid}/apikeys/${JSON.parse(jsonparams).akid}`,
        method: 'DELETE'
      }),
      providesTags: ['AccountApiKeys'],
    }),

    getOrders: builder.query({
      query: () => '/orders',
      providesTags: ['Order'],
    }),
    addOrder: builder.mutation({
      query: (body) => ({
        url: '/orders',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Order'],
    }),
    deleteOrder: builder.mutation({
      query: (body) => ({
        url: '/orders',
        method: 'DELETE',
        body
      }),
      invalidatesTags: ['Order'],
    }),
    updateOrder: builder.mutation({
      query: (oid) => ({
        url: `/orders/${oid}`,
        method: 'PATCH'
      }),
      invalidatesTags: ['Order','SubOrders'],
    }),
    getSubOrders: builder.query({
      query: (oid) => ({ url: `/orders/${oid}/suborders` }),
      providesTags: ['SubOrders'],
    }),
    deleteSubOrder: builder.mutation({
      query: (jsonparams) => ({
        url: `/orders/${JSON.parse(jsonparams).oid}/suborders/${JSON.parse(jsonparams).soid}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['SubOrders'],
    }),
    updateSubOrder: builder.mutation({
      query: (jsonparams) => ({
        url: `/orders/${JSON.parse(jsonparams).oid}/suborders/${JSON.parse(jsonparams).soid}`,
        method: 'PATCH'
      }),
      invalidatesTags: ['SubOrders'],
    }),
    get2FACode: builder.query({
      query: () => '/2fa/generate',
      providesTags: ['Profile'],
    }),
    get2FAVerify: builder.mutation({
      query: (body) => ({
        url: '/2fa/verify',
        method: 'POST',
        body
      }),
      providesTags: ['Profile'],
    }),
    getPinVerify: builder.mutation({
      query: (jsonparams) => ({
        url: '/pin/verify',
        method: 'POST',
        body: jsonparams
      }),
      providesTags: ['ProfilePassword']
    })
  })
})

export const {
  useRefreshTokenMutation,

  useGetProfileQuery,
  useUpdateProfileMutation,

  useGetPairsQuery,
  useAddPairMutation,
  useDeletePairMutation,

  useGetAccountsQuery,
  useAddAccountMutation,
  useUpdateAccountMutation,
  useDeleteAccountMutation,

  useGetAccountsBalanceQuery,

  useGetAccountQuery,
  useGetAccountBalanceQuery,
  useGetAccountLiveBalanceQuery,

  useGetAccountApiKeysQuery,
  useAddAccountApiKeysMutation,
  useDeleteAccountApiKeysMutation,

  useGetOrdersQuery,
  useAddOrderMutation,
  useDeleteOrderMutation,
  useUpdateOrderMutation,

  useGetSubOrdersQuery,
  useDeleteSubOrderMutation,
  useUpdateSubOrderMutation,

  useGet2FACodeQuery,
  useGet2FAVerifyMutation,
  useGetPinVerifyMutation

} = userApi
