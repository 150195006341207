import { isRejectedWithValue } from '@reduxjs/toolkit'
import { toast } from 'react-hot-toast';
import { logout } from "../slices/auth";
import { Navigate } from "react-router-dom";

/**
 * Log a warning and show a toast!
 */
export const ErrorLogger = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
  if (isRejectedWithValue(action)) {
    const errorMessage = (typeof action.payload.data === 'string' || action.payload.data instanceof String) ? action.payload.data : action.payload.data?.msg;
    toast.error(errorMessage);

    if(action.payload.status === 401) {
      api.dispatch(logout());
      localStorage.removeItem("kniteToken");
      <Navigate to="/login" state="" />
    }
  }

  return next(action)
}