import React from 'react';
import Header from '../components/Header';
import MarketsList from '../components/MarketsList';

export default function Markets() {
  return (
    <>
      <Header />
      <div className="container-fluid mtb15 no-fluid">
        <div className="row sm-gutters">
          <div className="col-md-12">
            <MarketsList />
          </div>
        </div>
      </div>
    </>
  );
}
