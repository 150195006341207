import React, { useEffect, useCallback } from "react";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import useWebSocket from 'react-use-websocket';
import { kucoinApi } from './services/kucoin';
import { setKucoinData, selectExchanges } from './slices/exchanges';
import { generateId } from './helpers/utils';

import Login from './pages/Login';
import Notfound from './pages/NotFound';
import Trade from './pages/Trade';
import Markets from './pages/Markets';
import Accounts from './pages/Accounts';
import Profile from './pages/Profile';
import Orders from './pages/Orders';
import OtpVerify from './pages/Otp-verify';
import Wallet from './pages/Wallet';

import { selectAuthenticated, selectUser } from './slices/auth';
import { useRefreshTokenMutation } from './services/user'

const jwt = require('jsonwebtoken');

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useSelector(selectAuthenticated);
  const user = useSelector(selectUser)

  const isTwoFactorPass = user.twofactor === true ? user.twofactor === user.twofactorvalidated : true
  //const isTwoFactorPass = user.twofactor == true

  let location = useLocation()
  return isAuthenticated && isTwoFactorPass ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  )
}

export default function App() {
  const dispatch = useDispatch();
  const exchanges = useSelector(selectExchanges);

  const [refreshToken, { isSuccess }] = useRefreshTokenMutation();
  const isAuthenticated = useSelector(selectAuthenticated);

  useWebSocket("wss://stream.binance.com:9443/stream", {
    share: true,
    onOpen: (socketEvent) => {
      console.log("Binance websocket opened");
    },
    filter: () => { return false; }
  });

  const getKucoinUrl = useCallback(() => {
    const bulletToken = dispatch(kucoinApi.endpoints.getBulletToken.initiate());
    return bulletToken.then((response) => {
      const url = response.data.data.instanceServers[0].endpoint + "?token=" + response.data.data.token;
      dispatch(setKucoinData({
        url: url,
        pingInterval: response.data.data.instanceServers[0].pingInterval
      }));
      return url;
    })
  }, [dispatch]);

  useWebSocket(getKucoinUrl, {
    share: true,
    onOpen: (socketEvent) => {
      console.log("Kucoin websocket opened");
      const ws = socketEvent.currentTarget;
      setInterval((ws) => {
        const subscribeId = generateId();
        ws.send(JSON.stringify({
          id: subscribeId,
          type: "ping",
        }));
      }, exchanges.kucoin.pingInterval, ws);
    },
    filter: () => { return false; }
  });

  useEffect(() => {
    document.body.classList.add('dark');
  }, []);

  useEffect(() => {
      if (isAuthenticated) {
        const interval = setInterval(
          () => {
            if(localStorage.getItem('kniteToken')) {
              let token = jwt.decode(JSON.parse(localStorage.getItem('kniteToken')).token);
              let difference = token.exp - Math.floor(Date.now() / 1000)
              if(difference > 0 && difference < (60 * 5)) {
                console.log("Refreshing token...");
                refreshToken().unwrap();
              }
            }
          },
          60 * 1000
        );
        return () => clearInterval(interval);
      }
    },
    // respond to changes in isLoggedIn
    [refreshToken, isAuthenticated]
  );
  
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/otpverify" element={<OtpVerify />}></Route>
          <Route path="/" element={<ProtectedRoute><Trade /></ProtectedRoute>}></Route>
          <Route path="/markets" element={<ProtectedRoute><Markets /></ProtectedRoute>}></Route>
          <Route path="/orders" element={<ProtectedRoute><Orders /></ProtectedRoute>}></Route>
          <Route path="/accounts" element={<ProtectedRoute><Accounts /></ProtectedRoute>}></Route>
          <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>}></Route>
          <Route path="/twofactor" element={<ProtectedRoute><twofactor /></ProtectedRoute>}></Route>
          <Route path="/wallet" element={<Wallet />}></Route>
          <Route path="*" element={<Notfound />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

