import React, { useState } from 'react';
import { ButtonGroup, Dropdown, DropdownButton, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { userApi, useGetAccountsQuery, useAddAccountMutation } from '../services/user';
import { setActiveAccount, selectActiveAccount } from '../slices/account';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from 'react-hot-toast';

import Table from 'react-bootstrap/Table'
import Loading from './Loading';

export default function AccountsList() {
  const [isOpenAddModal, setIsOpenAddModal] = React.useState(false);
  const { data: accounts = [], isLoading } = useGetAccountsQuery();
  const [AddAccount] = useAddAccountMutation();
  const dispatch = useDispatch();
  const [accountFilter, setAccountFilter] = useState("");
  const activeAccount = useSelector(selectActiveAccount);

  const hideModal = () => { setIsOpenAddModal(false); };

  //const [skipLoadLiveBalance, setSkipLoadLiveBalance] = useState(true);
  //const { data: accountLiveBalance = [], isSuccess: accountLiveBalanceIsSuccess, isUninitialized: accountLiveBalanceIsUninitialized } = useGetAccountLiveBalanceQuery(cAccount, { skip: skipLoadLiveBalance });

  const handleAddAccount = async (formValue) => {
    try {
      const newValues = JSON.stringify({ nickname: formValue.accountNickname, active: formValue.accountActive, name: formValue.accountFirstname, surname: formValue.accountLastname });
      const AccountCreated = await AddAccount(newValues).unwrap();
      //alert(AccountCreated);
      if (AccountCreated) {
        hideModal()
        toast.success("Account created");
        dispatch(userApi.util.invalidateTags(['Accounts']))
        dispatch(setActiveAccount(AccountCreated))
      } else {
        toast.error("Operation failed");
      }
    }
    catch (e) {
      console.log("Error", e)
    }
  }



  function handleCheck(aid, val) {
    const apiUrl = `/api/protected/user/accounts/${aid}/patch`
    const token = JSON.parse(localStorage.getItem('kniteToken')).token;

    fetch(apiUrl, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "active": val
      }),
    })
      .then(
        dispatch(userApi.util.invalidateTags(['Accounts']))
      )
      .catch((err) => console.log('error: ', err))

    return
  };


  function handleCheckAll(val) {
    const apiUrl = `/api/protected/user/accounts/status`
    const token = JSON.parse(localStorage.getItem('kniteToken')).token;

    fetch(apiUrl, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "active": val
      }),
    })
      .then(
        dispatch(userApi.util.invalidateTags(['Accounts']))
      )
      .catch((err) => console.log('error: ', err))

    var check = true;
    if (val === 0) check = false;

    var inputs = document.getElementsByTagName("input");
    for (var i in inputs) {
      if (inputs[i].type === 'checkbox') {
        inputs[i].checked = check;
      }
    }
    return
  };



  if (isLoading) return <Loading />;

  return (
    <>
      <div>
        <Modal
          size="lg"
          centered
          show={isOpenAddModal} onHide={hideModal}>
          <Modal.Header>
            <Modal.Title>Add a new account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className='col-md-12'>
                <Formik
                  initialValues={{ accountNickname: "", accountFirstname: "", accountLastname: "", accountActive: "1" }}
                  validationSchema={
                    Yup.object().shape({
                      'accountNickname': Yup.string().required("This field is required!"),
                      'accountFirstname': Yup.string().required("This field is required!"),
                      'accountLastname': Yup.string().when('accountFirstname', {
                        is: (accountFirstname) => !accountFirstname || accountFirstname.length === 0,
                        then: Yup.string().required("At least one of the fields Firstname or Lastname is required"),
                      })
                    })
                  }
                  onSubmit={handleAddAccount}
                >
                  <Form>
                    <div className="form-row">
                      <div className="col-md-6">
                        <label htmlFor="accountNickname">Nickname or Alias</label>
                        <Field id="accountNickname" autoFocus="true" name="accountNickname" type="text" className="form-control" placeholder="Nickname or alias" />
                        <ErrorMessage
                          name="accountNickname"
                          component="div"
                          className="alert alert-danger"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="accountNickname">Active</label>
                        <Field id="accountActive" name="accountActive" type="text" className="form-control" component="select">
                          <option value="1">Enabled</option>
                          <option value="0">Disabled</option>
                        </Field>
                      </div>
                      <div className="col-md-6 m25">
                        <label htmlFor="accountFirstname">First name (for report purpose)</label>
                        <Field id="accountFirstname" name="accountFirstname" type="text" className="form-control" placeholder="Firstname" />
                        <ErrorMessage
                          name="accountFirstname"
                          component="div"
                          className="alert alert-danger"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="accountLastname">Last name (for report purpose)</label>
                        <Field id="accountLastname" name="accountLastname" type="text" className="form-control" placeholder="Lastname" />
                        <ErrorMessage
                          name="accountLastname"
                          component="div"
                          className="alert alert-danger"
                        />
                      </div>
                      <div className="col-md-12 m15">
                        <button type="submit" className='btn btn-success'>Create</button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </Modal.Body >
        </Modal >
      </div>

      <div className="markets">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="table-accounts-div">

                <div className="row" style={{ "margin-right": "0px", "margin-top": "2px" }}>
                  <div className="col-md-8">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-sm">
                          <i className="icon ion-md-search"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        aria-describedby="inputGroup-sizing-sm"
                        onChange={e => setAccountFilter(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <ButtonGroup name="account-actions" id="account-actions">
                      <DropdownButton as={ButtonGroup} title="Action" id="bg-nested-dropdown" className='account-actions'>
                        <Dropdown.Item eventKey="1" onClick={() => setIsOpenAddModal(true)}>Add new</Dropdown.Item>
                        <Dropdown.Item eventKey="2" onClick={() => handleCheckAll(1)}>Active all</Dropdown.Item>
                        <Dropdown.Item eventKey="3" onClick={() => handleCheckAll(0)}>Disactive all</Dropdown.Item>
                        <Dropdown.Item eventKey="4" onClick={() => dispatch(setActiveAccount(-1))}>Balance</Dropdown.Item>
                      </DropdownButton>
                    </ButtonGroup>
                  </div>
                </div>


                <Table className='table-accounts-list' hover variant="dark" size="sm">
                  <thead>
                    <tr>
                      <th>Active</th>
                      <th>Order</th>
                      <th>Nickname</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody style={{ "max-height": "720px" }}>
                    {accounts.filter(el => el.nickname.includes(accountFilter.toUpperCase())).map((account) => (
                      <tr className={`${activeAccount === account.id ? "table-secondary" : ""}`}>
                        <td>
                          <div className="custom-control custom-switch">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              defaultChecked={account.active}
                              name={"chk_" + account.id}
                              id={"chk_" + account.id}
                              onChange={(e) => {

                                if (e.target.checked) {
                                  handleCheck(account.id, 1);
                                } else {
                                  handleCheck(account.id, 0);
                                }
                              }
                              }


                            />
                            <label
                              className="custom-control-label"
                              htmlFor={"chk_" + account.id}
                            ></label>
                          </div>
                        </td>
                        <td>{account.order_id}</td>
                        <td>{account.nickname}</td>
                        <td>
                          <input className='btn btn-primary btn-sm'
                            type="button"
                            onClick={() => dispatch(setActiveAccount(account.id))} value="View"></input>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>


              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
