import React from 'react';
import Header from '../components/Header';
import OrdersList from '../components/OrdersList';
import OrderDetails from '../components/OrderDetails';

import { useSelector } from 'react-redux';
import { selectActiveOrder } from '../slices/order';

export default function Orders() {

  const activeOrder = useSelector(selectActiveOrder);

  return (
    <>
      <Header />
      <div className="settings mtb15">

        <div className="col-md-12">
          <div className="card m15">
            <OrdersList />
          </div>
        </div>
        <div className="col-md-12">
          {activeOrder != null && (
            <OrderDetails />
          )}
        </div>
      </div>
    </>
  );
}
