import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = '/api/public/exchanges/kucoin'

export const kucoinApi = createApi({
  reducerPath: 'kucoinApi',
  baseQuery: fetchBaseQuery({
    baseUrl, prepareHeaders: (headers, { getState }) => {
      if (getState().auth.isAuthenticated) {
        const token = JSON.parse(localStorage.getItem('kniteToken')).token;
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ['KucoinBulletToken'],
  endpoints: (builder) => ({
    getBulletToken: builder.query({
      query: () => ({
        url: '/bullet_token',
        method: 'POST',
      }),
      invalidatesTags: ['KucoinBulletToken'],
    }),
    getAllTickers: builder.query({
      query: () => ({
        url: '/all_tickers',
        method: 'GET',
      }),
      transformResponse: (response) => response.reduce((accumulator, currentValue) => {
        accumulator[currentValue.symbolName] = {
          exchange: "Kucoin",
          exchangeId: 2,
          symbol: currentValue.symbolName,
          baseAsset: currentValue.baseCurrency,
          quoteAsset: currentValue.quoteCurrency,
          lastPrice: currentValue.last,
          change: currentValue.changeRate,
          high: currentValue.high,
          low: currentValue.low,
          volume: currentValue.vol,
          favorite: false,
          pairId: null
        }
        return accumulator;
      }, {})
    }),
  })
})

export const { useGetBulletTokenQuery, useGetAllTickersQuery } = kucoinApi
