import React from 'react';
import { useSelector } from 'react-redux';
import { selectActiveAccount } from '../slices/account';
import { useGetAccountsBalanceQuery } from '../services/user';
import Loading from './Loading';

export default function AccountsBalance() {
  const activeAccount = useSelector(selectActiveAccount);
  const cAccount = activeAccount;

  const { data: accountsBalance = [], isLoading: accountBalanceIsLoading } = useGetAccountsBalanceQuery(cAccount);

  if (accountBalanceIsLoading) return <Loading />;

  return (
    <>
      <div className="table-balance-div">
        <h5 className="card-title">
          Global balance
        </h5>

        <table className="table table-accounts-list">
          <thead>
            <tr>
              <th>Exchange</th>
              <th>Asset</th>
              <th align="right">Amount</th>
              <th align="right">Locked</th>
              <th align="right">Accounts</th>
            </tr>
          </thead>
          <tbody>
            {accountsBalance.map((balance, index) => (
              <tr>
                <td>{balance.exchange}</td>
                <td>{balance.asset}</td>
                <td align='right'>{balance.amount.toFixed(8)}</td>
                <td align='right'>{balance.locked.toFixed(8)}</td>
                <td align='center'>{balance.counts}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
