import { createSlice } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js';
import { format } from 'date-fns';

const initialState = {
  binance: {
    isWebSocket: true,
    url: "wss://stream.binance.com:9443/stream"
  },
  kucoin: {
    isWebSocket: true,
    url: null,
    pingInterval: null
  },
  therocktrading: {
    isWebSocket: false
  }
}

const exchangesSlice = createSlice({
  name: 'exchanges',
  initialState: initialState,
  reducers: {
    setKucoinData: (state, action) => {
      state.kucoin.url = action.payload.url;
      state.kucoin.pingInterval = action.payload.pingInterval;
    }
  }
})

export const { setKucoinData } = exchangesSlice.actions;
export const selectExchanges = state => state.exchanges;
export default exchangesSlice.reducer;

export const getExchangeMessage = {
  binance: {
    ticker: {
      channel: (pair) => {
        return pair.toLowerCase() + "@ticker"
      },
      subscribePayload: (id, params) => {
        return {
          id: id,
          method: "SUBSCRIBE",
          params: params,
        }
      },
      unsubscribePayload: (id, params) => {
        return {
          id: id,
          method: "UNSUBSCRIBE",
          params: params,
        }
      },
      messageFilter: (message, pair) => {
        if (typeof message?.data !== "undefined") {
          const message_type = message.data.e;
          const message_pair = message.data.s;
          return message_type === "24hrTicker" && message_pair === pair;
        }
        return false;
      },
      messageFormatter: (message) => {
        return {
          lastPrice: message?.data ? new BigNumber(message.data.c).toFormat(null, 1) : 0,
          priceChange: message?.data ? new BigNumber(message.data.P).toFormat(null, 1) : 0
        }
      }
    },
    trades: {
      channel: (pair) => {
        return pair.toLowerCase() + "@aggTrade"
      },
      subscribePayload: (id, params) => {
        return {
          id: id,
          method: "SUBSCRIBE",
          params: params,
        }
      },
      unsubscribePayload: (id, params) => {
        return {
          id: id,
          method: "UNSUBSCRIBE",
          params: params,
        }
      },
      messageFilter: (message, pair) => {
        if (typeof message?.data !== "undefined") {
          const message_type = message.data.e;
          const message_pair = message.data.s;
          return message_type === "aggTrade" && message_pair === pair;
        }
        return false;
      },
      messageFormatter: (message) => {
        return {
          time: message?.data ? format(message.data.T, "HH:mm:ss") : 0,
          price: message?.data ? new BigNumber(message.data.p).toFormat(null, 1) : 0,
          amount: message?.data ? new BigNumber(message.data.q).toFormat(null, 1) : 0,
          color: message?.data && message.data.m ? "green" : "red"
        }
      }
    },
    orderbook: {
      channel: (pair) => {
        return pair.toLowerCase() + "@depth20"
      },
      subscribePayload: (id, params) => {
        return {
          id: id,
          method: "SUBSCRIBE",
          params: params,
        }
      },
      unsubscribePayload: (id, params) => {
        return {
          id: id,
          method: "UNSUBSCRIBE",
          params: params,
        }
      },
      messageFilter: (message, pair) => {
        if (typeof message?.data !== "undefined") {
          const message_type = message.stream;
          return message_type === pair.toLowerCase() + "@depth20";
        }
        return false;
      },
      messageFormatter: (message) => {
        return {
          bids: message?.data ? message.data.bids : [],
          asks: message?.data ? message.data.asks : [],
        }
      }
    },
    symbols: {
      channel: () => {
        return "!ticker@arr"
      },
      subscribePayload: (id, params) => {
        return {
          id: id,
          method: "SUBSCRIBE",
          params: params,
        }
      },
      unsubscribePayload: (id, params) => {
        return {
          id: id,
          method: "UNSUBSCRIBE",
          params: params,
        }
      },
      messageFilter: (message) => {
        if (typeof message?.data !== "undefined") {
          const message_type = message.stream;
          return message_type === "!ticker@arr";
        }
        return false;
      },
      messageFormatter: (message) => {
        if (message?.data) {
          return message.data.map((value) => {
            return {
              symbol: value.s,
              lastPrice: value.c,
              change: value.P,
              high: value.h,
              low: value.l,
              volume: value.q
            }
          });
        }
        return [];
      }
    }
  },
  kucoin: {
    ticker: {
      channel: (pair) => {
        return pair
      },
      subscribePayload: (id, params) => {
        return {
          id: id,
          type: "subscribe",
          topic: "/market/snapshot:" + params.join(),
          privateChannel: false,
          response: false,
        }
      },
      unsubscribePayload: (id, params) => {
        return {
          id: id,
          type: "unsubscribe",
          topic: "/market/snapshot:" + params.join(),
          privateChannel: false,
          response: false,
        }
      },
      messageFilter: (message, pair) => {
        return message?.topic === "/market/snapshot:" + pair;
      },
      messageFormatter: (message) => {
        return {
          lastPrice: message?.data ? new BigNumber(message.data.data.lastTradedPrice).toFormat(null, 1) : 0,
          priceChange: message?.data ? new BigNumber(message.data.data.changeRate).toFormat(null, 1) : 0
        }
      }
    },
    trades: {
      channel: (pair) => {
        return pair
      },
      subscribePayload: (id, params) => {
        return {
          id: id,
          type: "subscribe",
          topic: "/market/match:" + params.join(),
          privateChannel: false,
          response: false,
        }
      },
      unsubscribePayload: (id, params) => {
        return {
          id: id,
          type: "subscribe",
          topic: "/market/match:" + params.join(),
          privateChannel: false,
          response: false,
        }
      },
      messageFilter: (message, pair) => {
        return message?.topic === "/market/match:" + pair;
      },
      messageFormatter: (message) => {
        const nanoseconds = new BigNumber(message.data.time);
        const milliseconds = new BigNumber(nanoseconds / 1000000);
        const timestamp = milliseconds.integerValue().toNumber();
        return {
          time: message?.data ? format(new Date(timestamp), "HH:mm:ss") : "-",
          price: message?.data ? new BigNumber(message.data.price).toFormat(null, 1) : 0,
          amount: message?.data ? new BigNumber(message.data.size).toFormat(null, 1) : 0,
          color: message?.data && message.data.side === "buy" ? "green" : "red"
        }
      }
    },
    orderbook: {
      channel: (pair) => {
        return pair
      },
      subscribePayload: (id, params) => {
        return {
          id: id,
          type: "subscribe",
          topic: "/spotMarket/level2Depth50:" + params.join(),
          privateChannel: false,
          response: false,
        }
      },
      unsubscribePayload: (id, params) => {
        return {
          id: id,
          type: "subscribe",
          topic: "/spotMarket/level2Depth50:" + params.join(),
          privateChannel: false,
          response: false,
        }
      },
      messageFilter: (message, pair) => {
        return message?.topic === "/spotMarket/level2Depth50:" + pair;
      },
      messageFormatter: (message) => {
        return {
          bids: message?.data ? message.data.bids : [],
          asks: message?.data ? message.data.asks : [],
        }
      }
    }
  }
}
