import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from './Loading';
import { useGetOrdersQuery } from '../services/user';
import { setActiveOrder, selectActiveOrder, } from '../slices/order';
import { userApi, useUpdateOrderMutation } from '../services/user';
import toast from 'react-hot-toast';
import '../assets/scss/module/_orders-list.scss';

export default function OrdersList() {
  const { data = [], isLoading } = useGetOrdersQuery();
  const dispatch = useDispatch();
  const activeOrder = useSelector(selectActiveOrder);
  const [UpdateOrder] = useUpdateOrderMutation();

  const handleOrderUpdate = async (oid) => {
    try {
      if (window.confirm("Are you sure to update order " + oid + "?")) {
        const updOrder = await UpdateOrder(oid).unwrap();
        if (updOrder === "Updated" || updOrder === "Pending") {
          toast.success("Orders updated");
          dispatch(setActiveOrder(oid))
        } else {
          toast.error("Operation failed");
        }
      }
    }
    catch (e) {
      console.log("Error", e)
    }
  }


  if (isLoading) return <Loading />;

  return (
    <>

      <div className="order-list">
        <div className='table-responsive table-orders-div'>
          <table className="table table-orders-list">
            <thead>
              <tr>
                <th>Created</th>
                <th>ID</th>
                <th>Exchange</th>
                <th>Asset</th>
                <th align="center">Type</th>
                <th align="center">Price</th>
                <th align="center">Amount</th>
                <th align="center">Orders</th>
                <th align="center">Status</th>
                <th align="center">Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((order, index) => (
                <tr className={`${activeOrder === order.id ? "table-secondary" : ""}`}>
                  <td>{order.timestamp}</td>
                  <td>{order.id}</td>
                  <td>{order.exchange}</td>
                  <td>{order.asset}</td>
                  <td align="left">{order.type}</td>
                  <td align="left">{order.price}</td>
                  <td align="left">{order.amount}
                    {{
                      perc: " %",
                      fixed: ""
                    }[order.amount_type]}
                  </td>
                  <td align="left">{order.subcount}</td>
                  <td align="left">{order.status}</td>
                  <td align="center" class="text-nowrap">
                    <input className='btn btn-primary btn-sm' type="button" onClick={() => dispatch(setActiveOrder(order.id))} value="View"></input>
                    &nbsp;
                    {order.status == "PENDING" ? <input className='btn btn-secondary btn-sm' type="button" onClick={() => handleOrderUpdate(order.id)} value="Update"></input> : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

    </>
  );
}
