import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setActivePair, selectActivePair, setPairLastPrice } from '../../slices/pair';
import { therocktradingApi } from '../../services/therocktrading';

export default function SubscribedPairTRT(props) {
  const dispatch = useDispatch();
  const activePair = useSelector(selectActivePair);
  const currentPairData = props.pair;

  const { data } = therocktradingApi.useGetTickerQuery(currentPairData.base_asset + '-' + currentPairData.quote_asset, {
    pollingInterval: 1000,
  });
  if(activePair === currentPairData.id) {
    dispatch(setPairLastPrice(data?.last));
  }

  return (
    <>
      <tr className={`${activePair === currentPairData.id ? "table-secondary" : ""}`} onClick={() => dispatch(setActivePair(currentPairData))}>
        <td>
          <i className={`beacon exc-${currentPairData.exchange.toLowerCase()}-s`} title={currentPairData.exchange}></i> {currentPairData.base_asset}/{currentPairData.quote_asset}
        </td>
        <td>{data?.last}</td>
        <td className={data?.changePrice >= 0 ? "green" : "red"}>{data?.changePrice}%</td>
      </tr>
    </>
  );
}