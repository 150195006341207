import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useWebSocket from 'react-use-websocket';
import { setActivePair, selectActivePair, setPairLastPrice } from '../slices/pair';
import { selectExchanges, getExchangeMessage } from '../slices/exchanges';

export default function SubscribedPair(props) {
  const dispatch = useDispatch();
  const activePair = useSelector(selectActivePair);
  const exchanges = useSelector(selectExchanges);
  const currentExchange = props.pair.exchange.toLowerCase();
  const currentPair = props.pair.pair;
  const currentPairData = props.pair;

  const {
    lastJsonMessage
  } = useWebSocket(exchanges[currentExchange].url, {
    share: true,
    filter: (messageEvent) => {
      const message = JSON.parse(messageEvent.data);
      return getExchangeMessage[currentExchange].ticker.messageFilter(message, currentPair);
    }
  });

  const data = getExchangeMessage[currentExchange].ticker.messageFormatter(lastJsonMessage);
  if(activePair === currentPairData.id) {
    dispatch(setPairLastPrice(data.lastPrice));
  }
  
  return (
    <>
      <tr className={`${activePair === currentPairData.id ? "table-secondary" : ""}`} onClick={() => dispatch(setActivePair(currentPairData))}>
        <td>
          <i className={`beacon exc-${currentPairData.exchange.toLowerCase()}-s`} title={currentPairData.exchange}></i> {currentPairData.base_asset}/{currentPairData.quote_asset}
        </td>
        <td>{data.lastPrice}</td>
        <td className={data.priceChange >= 0 ? "green" : "red"}>{data.priceChange}%</td>
      </tr>
    </>
  );
}