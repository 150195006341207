import React from 'react';
import { useSelector } from 'react-redux';
import { selectPairData } from '../../slices/pair';
import { therocktradingApi } from '../../services/therocktrading';
import Loading from '../Loading';
import BigNumber from 'bignumber.js';

export default function MarketHistory() {
  const pairData = useSelector(selectPairData);

  const { data: tradeHistory, isLoading } = therocktradingApi.useGetTradesQuery(pairData.base_asset + '-' + pairData.quote_asset, {
    pollingInterval: 3000,
  });

  if (isLoading) return <Loading />

  return (
    <>
      <div className="market-history">
        <h2 className="heading">Recent Trades</h2>
        <table className="table" style={{ "marginBottom": "0px", "lineHeight": "1.3" }}>
          <thead>
            <tr>
              <th>Price({pairData.quote_asset})</th>
              <th>Amount({pairData.base_asset})</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody style={{ "height": "720px" }}>
            {tradeHistory.map((trade, index) => (
              <tr key={index + "-" + trade.time}>
                <td className={`${trade.side == 'buy' ? "green" : "red"}`}>{trade.price}</td>
                <td>{new BigNumber(trade.size).toFormat(null, 1)}</td>
                <td>{trade.time}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
