import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useWebSocket from 'react-use-websocket';
import { useGetPairsQuery } from '../services/user';
import { selectExchanges, getExchangeMessage } from '../slices/exchanges';
import Loading from './Loading';
import SubscribedPair from './SubscribedPair';
import SubscribedPairTRT from './therocktrading/SubscribedPair';
import { generateId } from '../helpers/utils';

export default function SubscribedPairsList() {
  const { data: pairs = [], isLoading } = useGetPairsQuery();
  const exchanges = useSelector(selectExchanges);
  const [filter, setFilter] = useState("");

  const ws = {
    binance: useWebSocket(exchanges.binance.url, {
      share: true,
      filter: () => { return false; }
    }),
    kucoin: useWebSocket(exchanges.kucoin.url, {
      share: true,
      filter: () => { return false; }
    }),
  }

  useEffect(() => {
    if (!isLoading) {
      for (const currentExchange in exchanges) {
        if (exchanges[currentExchange].isWebSocket) {
          ws[currentExchange].sendJsonMessage(getExchangeMessage[currentExchange].ticker.subscribePayload(generateId(), pairs.filter((pair) => pair.exchange.toLowerCase() === currentExchange).map((pair) => getExchangeMessage[currentExchange].ticker.channel(pair.pair))));
        }
      }
    }
    return () => {
      if (!isLoading) {
        for (const currentExchange in exchanges) {
          if (exchanges[currentExchange].isWebSocket) {
            ws[currentExchange].sendJsonMessage(getExchangeMessage[currentExchange].ticker.unsubscribePayload(generateId(), pairs.filter((pair) => pair.exchange.toLowerCase() === currentExchange).map((pair) => getExchangeMessage[currentExchange].ticker.channel(pair.pair))));
          }
        }
      }
    }
  }, [isLoading]);

  if (isLoading) return <Loading />;

  const filteredPairs = pairs.filter((pair) => pair.pair.toLowerCase().indexOf(filter.toLowerCase()) >= 0 || filter === "");
  
  return (
    <>
      <div className="market-pairs">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-sm">
              <i className="icon ion-md-search"></i>
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            aria-describedby="inputGroup-sizing-sm"
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Pairs</th>
              <th>Last Price</th>
              <th>Change</th>
            </tr>
          </thead>
          <tbody style={{ "height": "720px" }}>
            {filteredPairs.map((pair) => (
              {
                'therocktrading': <SubscribedPairTRT key={pair.id} pair={pair} />
              }[pair.exchange.toLowerCase()] || <SubscribedPair key={pair.id} pair={pair} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}