import { createSlice } from '@reduxjs/toolkit'
import { userApi } from '../services/user'

const initialState = {
  active_order: null
}

const orderSlice = createSlice({
  name: 'order',
  initialState: initialState,
  reducers: {
    setActiveOrder: (state, action) => {
      state.active_order = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(userApi.endpoints.getOrders.matchFulfilled, (state, { payload }) => {
      if (payload !== undefined && state.active_order === null) {
        if (payload.length > 0) {
          const first_order = payload[0];
          state.active_order = first_order.id;
        }
      }
      return state;
    })
  }
})

export const { setActiveOrder } = orderSlice.actions;
export const selectActiveOrder = state => state.order.active_order;
export default orderSlice.reducer;

