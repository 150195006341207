import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from 'react-hot-toast';

import { useLoginMutation } from '../services/auth'
import { selectUser } from '../slices/auth';

export default function Login() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false)

  const [login, { data, isSuccess }] = useLoginMutation();
  //const isAuthenticated = useSelector(selectAuthenticated);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (isSuccess && Object.entries(data).length > 0) {
      toast.success('Authentication is valid');

      if (user.twofactor === true)
        navigate('/otpverify');
      else
        navigate('/');
    }

  }, [data, isSuccess, navigate])

  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("This field is required!"),
    password: Yup.string().required("This field is required!"),
  });

  const handleLogin = async (formValue) => {
    try {
      setLoading(true);
      const { username, password } = formValue;
      await login({ username, password }).unwrap();
    }
    catch (e) {
      setLoading(false);
      console.log("Error", e)
    }
  }
  /*
    if (isAuthenticated) {
      return <Navigate to="/otpverify" />;
    }
  */
  return (
    <>
      <div className="vh-100 d-flex justify-content-center">
        <div className="form-access my-auto">
          <div>
            <img src={'img/logo-light.svg'} alt="logo" />
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleLogin}
          >
            <Form>
              <span>Sign In</span>
              <div className="form-group">
                <Field name="username" type="text" className="form-control text-white" placeholder="Username" />
                <ErrorMessage
                  name="username"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <Field name="password" type="password" className="form-control text-white" placeholder="Password" />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <button type="submit" className="btn btn-primary btn-block" disabled={isLoading}>
                <div className="center">
                  {isLoading ?
                    (<span className="spinner-border spinner-border-sm"></span>)
                    :
                    (<span >Sign In</span>)
                  }

                </div>
              </button>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
}
