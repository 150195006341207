import { createSlice } from '@reduxjs/toolkit'
import { userApi } from '../services/user'

const initialState = {
  active_pair: null,
  pair_data: null,
  last_price: 0
}

const pairSlice = createSlice({
  name: 'pair',
  initialState: initialState,
  reducers: {
    setActivePair: (state, action) => {
      state.active_pair = action.payload.id;
      state.pair_data = action.payload;
      state.last_price = 0;
    },
    setPairLastPrice: (state, action) => {
      state.last_price = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(userApi.endpoints.getPairs.matchFulfilled, (state, { payload }) => {
      if (payload !== undefined) {
        if (payload.length > 0) {
          const first_pair = payload[0];
          state.active_pair = first_pair.id;
          state.pair_data = first_pair;
          state.last_price = 0;
        }
      }
      return state;
    })
  }
})

export const { setActivePair, setPairLastPrice } = pairSlice.actions;
export const selectActivePair = state => state.pair.active_pair;
export const selectPairData = state => state.pair.pair_data;
export const selectPairLastPrice = state => state.pair.last_price;
export default pairSlice.reducer;
