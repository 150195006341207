import React from 'react';
import { Link } from 'react-router-dom';

export default function MarketNews() {
  return (
    <>
      <div className="market-news mt15">
        <h2 className="heading">Market Alert & News</h2>
        <ul>
          {/*
          <li>
            <Link to="/news-details">
              <strong>Marshall Wace hedge fund giant to reportedly dive into crypto</strong>
              Marshall Wace is reportedly still discussing the size of its new digital currency-related portfolio with potential investors.
              <span>2021-07-04 20:22</span>
            </Link>
          </li>
          <li>
            <Link to="/news-details">
              <strong>MACD-4H Signal</strong>
              SELL ZECBTC on Binance
              <span>2021-07-04 13:22</span>
            </Link>
          </li>
          <li>
            <Link to="/news-details">
              <strong>MACD-4H Signal</strong>
              BUY TRXBTC on Binance
              <span>2021-07-03 10:41</span>
            </Link>
          </li>
          <li>
            <Link to="/news-details">
              <strong>MACD-4H Signal</strong>
              BUY ZECBTC on Binance
              <span>2021-07-03 02:34</span>
            </Link>
          </li>
          <li>
            <Link to="/news-details">
              <strong>MACD-4H Signal</strong>
              BUY LTCBTC on Binance
              <span>2019-07-02 23:54</span>
            </Link>
          </li>
  */}
        </ul>
      </div>
    </>
  );
}
