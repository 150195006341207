import { createSlice } from '@reduxjs/toolkit'
import { userApi } from '../services/user'

const initialState = {
  active_account: null
}

const accountSlice = createSlice({
  name: 'account',
  initialState: initialState,
  reducers: {
    setActiveAccount: (state, action) => {
      state.active_account = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(userApi.endpoints.getAccounts.matchFulfilled, (state, { payload }) => {
      if (payload !== undefined) {
        if (payload.length > 0) {
          if (state.active_account === undefined) {
            console.log(state.active_account);
            const first_account = payload[0];
            state.active_account = first_account.id;
          }
        }
      }
      return state;
    })
  }
})

export const { setActiveAccount } = accountSlice.actions;
export const selectActiveAccount = state => state.account.active_account;
export default accountSlice.reducer;

