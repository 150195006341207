import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectPairData, selectPairLastPrice } from '../slices/pair';
import { Tabs, Tab, Modal } from 'react-bootstrap';
import { userApi, useAddOrderMutation, useGetPinVerifyMutation } from '../services/user';
import { setActiveOrder } from '../slices/order';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from 'react-hot-toast';

export default function MarketTrade(props) {

  const [AddMainOrder] = useAddOrderMutation();
  const [VerifyPin, { pin }] = useGetPinVerifyMutation();
  const pinRef = useRef(null);

  const pairData = useSelector(selectPairData);
  const pairMarketPrice = useSelector(selectPairLastPrice);
  const dispatch = useDispatch();


  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [ModalData, setModalData] = React.useState(false);

  const hideModal = () => { setIsOpenModal(false); };

  //Bottun Fixed Amount in Limit > Buy
  const [viewLB, setShowLB] = useState(false);
  const toggleShowLB = () => setShowLB(!viewLB);

  const [selectedOptionLB, setSelectedOptionLB] = React.useState(false);
  const selectLBQt = (parameter) => event => {
    console.log(parameter)
    setShowLB(false);
    setSelectedOptionLB(parameter);
  }

  const changeLBQt = (event) => {
    if (event.target.value == 0) {
      toggleShowLB();
      setSelectedOptionLB(event.target.value);
    }
    else {
      setShowLB(false);
      setSelectedOptionLB(event.target.value);
    }
  }
  //end LB

  //Bottun Fixed Amount in Limit > Sell
  const [viewLS, setShowLS] = useState(false);
  const toggleShowLS = () => setShowLS(!viewLS);

  const [selectedOptionLS, setSelectedOptionLS] = useState("-1");
  const selectLSQt = (parameter) => event => {
    setShowLS(false);
    setSelectedOptionLS(parameter);
  }

  const changeLSQt = (event) => {
    if (event.target.value == 0) {
      toggleShowLS();
      setSelectedOptionLS(event.target.value);
    }
    else {
      setShowLS(false);
      setSelectedOptionLS(event.target.value);
    }
  }
  //end LS
  //Bottun Fixed Amount in Market > Buy
  const [viewMB, setShowMB] = useState(false);
  const toggleShowMB = () => setShowMB(!viewMB);

  const [selectedOptionMB, setSelectedOptionMB] = useState("-1");
  const selectMBQt = (parameter) => event => {
    setShowMB(false);
    setSelectedOptionMB(parameter);
  }

  const changeMBQt = (event) => {
    if (event.target.value == 0) {
      toggleShowMB();
      setSelectedOptionMB(event.target.value);
    }
    else {
      setShowMB(false);
      setSelectedOptionMB(event.target.value);
    }
  }
  //end MB

  //Bottun Fixed Amount in Market > Sell
  const [viewMS, setShowMS] = useState(false);
  const toggleShowMS = () => setShowMS(!viewMS);

  const [selectedOptionMS, setSelectedOptionMS] = useState("-1");
  const selectMSQt = (parameter) => event => {
    setShowMS(false);
    setSelectedOptionMS(parameter);
  }

  const changeMSQt = (event) => {
    if (event.target.value == 0) {
      toggleShowMS();
      setSelectedOptionMS(event.target.value);
    }
    else {
      setShowMS(false);
      setSelectedOptionMS(event.target.value);
    }
  }
  //end MS

  if (pairData === null) return "";

  const options = [];
  for (let i = 1; i <= 100; i = i + 1) {
    options.push({ value: i, label: `${i}%` });
  }

  //PIN MASK



  const handleTrade = async (formValue) => {
    //PIN verify
    const res = await VerifyPin(formValue.security_pin).unwrap();
    if (res === "Valid") {
      try {

        ModalData["market_price"] = pairMarketPrice
        const OrderValues = JSON.stringify(ModalData);
        const oid = await AddMainOrder(OrderValues).unwrap();
        setIsOpenModal(false);
        toast.success("Main order created");
        dispatch(setActiveOrder(oid))
        dispatch(userApi.util.invalidateTags(['Order']))
      }
      catch (e) {
        console.log("Error", e)
      }
    } else {
      toast.error("Invalid PIN");
    }
  }


  const verifyTrade = async (formValues) => {
    //alert(JSON.stringify(formValues, null, 2));
    var actualQT = formValues.operation === "buy" && formValues.type === "limit" ? selectedOptionLB
      : formValues.operation === "sell" && formValues.type === "limit" ? selectedOptionLS
        : formValues.operation === "buy" && formValues.type === "market" ? selectedOptionMB
          : formValues.operation === "sell" && formValues.type === "market" ? selectedOptionMS : "0";

    formValues["actualQT"] = '' + actualQT;
    formValues["qt"] = '' + formValues["qt"];
    formValues["accounts_count"] = Number(props.Accounts);

    setModalData(formValues);
    setIsOpenModal(true);
    pinRef.current.focus();
  }


  const validationTradeLimitSchema = Yup.object().shape({
    price: Yup.number().required("This field is required!"),
    qt: Yup.number().moreThan(-1, "Must be selected").required("This field is required!"),

  });
  const validationTradeMarketSchema = Yup.object().shape({
    qt: Yup.number().moreThan(-1, "Must be selected").required("This field is required!")
  });

  const validationTradeOrderSchema = Yup.object().shape({
    security_pin: Yup.string()
      .min(6, "Too short!")
      .required("Pin is required!")
  });

  return (
    <>
      <div>
        <Modal
          size="lg"
          centered
          show={isOpenModal} onHide={hideModal}>
          <Modal.Header>
            <Modal.Title>Trade confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className='col-md-6'>
                <span className="market-trade-white-modal">
                  <h2>Your trade</h2>
                </span>
                <span>
                  <div className='row'>
                    <div className='col-md-4'>
                      Exchange<br />
                      Order type<br />
                      Price type<br />
                      Pair<br />
                      Price<br />
                      Amount<br />
                    </div>
                    <div className='col-md-8'>
                      {ModalData.exchange}<br />
                      {ModalData.operation}<br />
                      {ModalData.type}<br />
                      {ModalData.base_asset + "/" + ModalData.quote_asset}<br />
                      {ModalData.type === "market" ? "market" : ModalData.price}<br />
                      {ModalData.actualQT != "0" ? ModalData.actualQT : ModalData.fixedamount}&nbsp;{ModalData.actualQT == "0" ? "(fixed)" : "(%)"}
                    </div>
                  </div>
                </span>

              </div>
              <div className='col-md-6'>
                <div className="market-trade-white-modal">

                  <Formik onSubmit={handleTrade} initialValues={{
                    security_pin: "",
                    inputHide: "",
                    inputClear: ""
                  }}
                    validationSchema={validationTradeOrderSchema}
                  >
                    {(formik) => (
                      <Form>
                        <div className='col-md-12'>
                          <h2>Security PIN</h2>
                        </div>
                        <div className='col-md-12'>
                          <ul>
                            <li key="modal_text">To ensure system security, trade requests must be authenticated</li>
                          </ul>
                        </div>
                        <div className='col-md-12 m15'>
                          <Field innerRef={pinRef} name="security_pin" type="password" className="form-control" placeholder="Security PIN" />
                          <ErrorMessage id="pinError" name="security_pin" component="div" className="alert alert-danger" />

                        </div>
                        <div className='col-md-12'>
                          <br />
                          {ModalData.operation === "buy"
                            ? <button disabled={formik.isSubmitting} type="Submit" className='btn buy'>Buy for {props.Accounts} accounts</button>
                            : <button disabled={formik.isSubmitting} type="Submit" className='btn sell'>Sell for {props.Accounts} accounts</button>}

                          &nbsp;
                          <button type="Button" className='btn btn-secondary' onClick={hideModal}>Cancel</button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Modal.Body >
        </Modal >



        <div className="market-trade" style={{ "height": "232px" }}>
          <Tabs defaultActiveKey="market">
            <Tab eventKey="market" title="Market">
              <div className="d-flex justify-content-between">
                <div className="market-trade-buy">
                  <Formik name="formMB" id="formMB" enableReinitialize
                    initialValues={{
                      operation: "buy",
                      exchange: pairData.exchange,
                      exchange_id: pairData.exchange_id,
                      base_asset: pairData.base_asset,
                      quote_asset: pairData.quote_asset,
                      type: "market",
                      price: "",
                      qt: selectedOptionMB,
                      fixedamount: "0"
                    }}
                    validationSchema={validationTradeMarketSchema}
                    onSubmit={verifyTrade}
                  >
                    <Form>
                      <Field type="hidden" name="operation" id="operationMB" value="Buy" />
                      <Field type="hidden" name="exchange" id="exchangeMB" />
                      <Field type="hidden" name="exchange_id" id="exchange_idMB" />
                      <Field type="hidden" name="type" id="typeMB" />
                      <Field type="hidden" name="base_asset" id="base_assetMB" />
                      <Field type="hidden" name="quote_asset" id="quote_assetMB" />
                      <div className="input-group" style={{ "margin-bottom": "10px" }}>
                        <div className="input-group-prepend">
                          <i className="input-group-text icon ion-md-lock"></i>
                        </div>
                        <input
                          id="price"
                          name="price"
                          type="number"
                          className="form-control plaintext readonly"
                          placeholder="Market Price"
                          disabled
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">{pairData.quote_asset}</span>
                        </div>
                      </div>
                      <ul className="market-trade-list" style={{ "margin-bottom": "5px" }}>
                        <li key="MBli10">
                          <a href="#!" className={selectedOptionMB === 10 ? 'bggreen' : ''} onClick={selectMBQt(10)}>10%</a>
                        </li>
                        <li key="MBli25">
                          <a href="#!" className={selectedOptionMB === 25 ? 'bggreen' : ''} onClick={selectMBQt(25)}>25%</a>
                        </li>
                        <li key="MBli50">
                          <a href="#!" className={selectedOptionMB === 50 ? 'bggreen' : ''} onClick={selectMBQt(50)}>50%</a>
                        </li>
                        <li key="MBli100">
                          <a href="#!" className={selectedOptionMB === 100 ? 'bggreen' : ''} onClick={selectMBQt(100)}>100%</a>
                        </li>
                        <li key="MBliCustom">
                          <Field as="select" name="qt" id="qtMB" onChange={changeMBQt} >
                            <option value="-1">% Amount</option>
                            <option value="0">Fixed</option>
                            {options.length > 0 && options.map((item) =>
                              <option value={item.value}>{item.label}</option>
                            )}
                          </Field>
                          <ErrorMessage
                            id="qtMBerror"
                            name="qt"
                            component="div"
                            className="alert alert-danger"
                          />
                        </li>
                      </ul>
                      {viewMB && (
                        <div className="input-group" style={{ "margin-bottom": "10px" }}>
                          <Field
                            id="fixedamountMB"
                            name="fixedamount"
                            type="text"
                            className="form-control"
                            placeholder="Amount"
                            required
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">{pairData.base_asset}</span>
                          </div>
                        </div>
                      )}
                      <button type="submit" className="btn buy" style={{ "margin-top": "0px" }}>
                        Buy <span class="badge badge-pill badge-light" title="Active accounts">{props.Accounts}</span>
                      </button>
                    </Form>
                  </Formik>
                </div>
                <div className="market-trade-sell">
                  <Formik name="formMS" id="formMS" enableReinitialize
                    initialValues={{
                      operation: "sell",
                      exchange: pairData.exchange,
                      exchange_id: pairData.exchange_id,
                      base_asset: pairData.base_asset,
                      quote_asset: pairData.quote_asset,
                      type: "market",
                      price: "",
                      qt: selectedOptionMS,
                      fixedamount: "0"
                    }}
                    validationSchema={validationTradeMarketSchema}
                    onSubmit={verifyTrade}
                  >
                    <Form>
                      <Field type="hidden" name="operation" id="operationMS" value="Sell" />
                      <Field type="hidden" name="exchange" id="exchangeMS" />
                      <Field type="hidden" name="exchange_id" id="exchange_idMS" />
                      <Field type="hidden" name="type" id="typeMS" />
                      <Field type="hidden" name="base_asset" id="base_assetMS" />
                      <Field type="hidden" name="quote_asset" id="quote_assetMS" />
                      <div className="input-group" style={{ "margin-bottom": "10px" }}>
                        <div className="input-group-prepend">
                          <i className="input-group-text icon ion-md-lock"></i>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Market Price"
                          disabled
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">{pairData.quote_asset}</span>
                        </div>
                      </div>
                      <ul className="market-trade-list" style={{ "margin-bottom": "5px" }}>
                        <li key="MSli10">
                          <a href="#!" className={selectedOptionMS === 10 ? 'bgred' : ''} onClick={selectMSQt(10)}>10%</a>
                        </li>
                        <li key="MSli25">
                          <a href="#!" className={selectedOptionMS === 25 ? 'bgred' : ''} onClick={selectMSQt(25)}>25%</a>
                        </li>
                        <li key="MSli50">
                          <a href="#!" className={selectedOptionMS === 50 ? 'bgred' : ''} onClick={selectMSQt(50)}>50%</a>
                        </li>
                        <li key="MSli100">
                          <a href="#!" className={selectedOptionMS === 100 ? 'bgred' : ''} onClick={selectMSQt(100)}>100%</a>
                        </li>
                        <li key="MSliCustom">
                          <Field as="select" name="qt" id="qtMS" onChange={changeMSQt} >
                            <option value="-1" keu="-1">% Amount</option>
                            <option value="0" keu="0">Fixed</option>
                            {options.length > 0 && options.map((item) =>
                              <option value={item.value} keu={item.value}>{item.label}</option>
                            )}
                          </Field>
                          <ErrorMessage
                            id="qtMS"
                            name="qt"
                            component="div"
                            className="alert alert-danger"
                          />
                        </li>
                      </ul>
                      {viewMS && (
                        <div className="input-group" id="marketSellAmount" style={{ "margin-bottom": "10px" }}>
                          <Field
                            id="fixedamountMS"
                            name="fixedamount"
                            type="text"
                            className="form-control"
                            placeholder="Amount"
                            required
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">{pairData.base_asset}</span>
                          </div>
                        </div>
                      )}
                      <button className="btn sell" style={{ "margin-top": "0px" }}>
                        Sell <span class="badge badge-pill badge-light" title="Active accounts">{props.Accounts}</span>
                      </button>
                    </Form>
                  </Formik>
                </div>
              </div>
            </Tab>
            <Tab eventKey="limit" title="Limit">
              <div className="d-flex justify-content-between">
                <div className="market-trade-buy">
                  <Formik name="formLB" id="formLB" enableReinitialize
                    initialValues={{
                      operation: "buy",
                      exchange: pairData.exchange,
                      exchange_id: pairData.exchange_id,
                      base_asset: pairData.base_asset,
                      quote_asset: pairData.quote_asset,
                      type: "limit",
                      price: "",
                      qt: selectedOptionLB,
                      fixedamount: "0"
                    }}
                    validationSchema={validationTradeLimitSchema}
                    onSubmit={verifyTrade}
                  >
                    <Form>
                      <Field type="hidden" name="operation" id="operationLB" value="Buy" />
                      <Field type="hidden" name="exchange" id="exchangeLB" />
                      <Field type="hidden" name="exchange_id" id="exchange_idLB" />
                      <Field type="hidden" name="type" id="typeLB" />
                      <Field type="hidden" name="base_asset" id="base_assetLB" />
                      <Field type="hidden" name="quote_asset" id="quote_assetLB" />
                      <div className="input-group" style={{ "margin-bottom": "10px" }} >
                        <Field
                          id="priceLB"
                          name="price"
                          type="number"
                          min="0.00000001"
                          step="0.00000001"
                          className="form-control"
                          placeholder="Price"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">{pairData.quote_asset}</span>
                        </div>
                      </div>
                      <ErrorMessage
                        id="priceLBerror"
                        name="price"
                        component="div"
                        className="alert alert-danger"
                      />
                      <ul className="market-trade-list" style={{ "margin-bottom": "5px" }}>
                        <li key="LBli10">
                          <a href="#!" className={selectedOptionLB === 10 ? 'bggreen' : ''} onClick={selectLBQt(10)}>10%</a>
                        </li>
                        <li key="LBli25">
                          <a href="#!" className={selectedOptionLB === 25 ? 'bggreen' : ''} onClick={selectLBQt(25)}>25%</a>
                        </li>
                        <li key="LBli50">
                          <a href="#!" className={selectedOptionLB === 50 ? 'bggreen' : ''} onClick={selectLBQt(50)}>50%</a>
                        </li>
                        <li key="LBli100">
                          <a href="#!" className={selectedOptionLB === 100 ? 'bggreen' : ''} onClick={selectLBQt(100)}>100%</a>
                        </li>
                        <li key="LBliCustom">
                          <Field as="select" name="qt" id="qtLB" onChange={changeLBQt} >
                            <option value="-1" key="-1">% Amount</option>
                            <option value="0" key="0">Fixed</option>
                            {options.length > 0 && options.map((item) =>
                              <option value={item.value} keu={item.value}>{item.label}</option>
                            )}
                          </Field>
                        </li>
                      </ul>
                      <ErrorMessage
                        id="qtLBerror"
                        name="qt"
                        component="div"
                        className="alert alert-danger"
                      />
                      {viewLB && (
                        <div className="input-group" id="limitBuyAmount" style={{ "margin-bottom": "10px" }}>
                          <Field
                            type="text"
                            name="fixedamount"
                            id="fixedamounteLB"
                            className="form-control"
                            placeholder="Amount"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">{pairData.base_asset}</span>
                          </div>
                        </div>
                      )}
                      <button
                        type="submit"
                        className="btn buy"
                        style={{ "margin-top": "0px" }}>
                        Buy <span class="badge badge-pill badge-light" title="Active accounts">{props.Accounts}</span>
                      </button>
                    </Form>
                  </Formik>
                </div>
                <div className="market-trade-sell">
                  <Formik name="formsLS" id="formsLS" enableReinitialize
                    initialValues={{
                      operation: "sell",
                      exchange: pairData.exchange,
                      exchange_id: pairData.exchange_id,
                      base_asset: pairData.base_asset,
                      quote_asset: pairData.quote_asset,
                      type: "limit",
                      price: "",
                      qt: selectedOptionLS,
                      fixedamount: "0"
                    }}
                    validationSchema={validationTradeLimitSchema}
                    onSubmit={verifyTrade}
                  >
                    <Form name="formLS" id="formsLS">
                      <Field type="hidden" name="operation" id="operationLS" value="Buy" />
                      <Field type="hidden" name="exchange" id="exchangeLS" />
                      <Field type="hidden" name="exchange_id" id="exchange_idLS" />
                      <Field type="hidden" name="type" id="typeLS" />
                      <Field type="hidden" name="base_asset" id="base_assetLS" />
                      <Field type="hidden" name="quote_asset" id="quote_assetLS" />
                      <div className="input-group" style={{ "margin-bottom": "10px" }}>
                        <Field
                          id="priceLS"
                          name="price"
                          type="number"
                          min="0.00000001"
                          step="0.00000001"
                          className="form-control"
                          placeholder="Price"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">{pairData.quote_asset}</span>
                        </div>
                      </div>
                      <ErrorMessage
                        id="priceLSerror"
                        name="price"
                        component="div"
                        className="alert alert-danger"
                      />

                      <ul className="market-trade-list" style={{ "margin-bottom": "5px" }}>
                        <li key="LSli10">
                          <a href="#!" className={selectedOptionLS === 10 ? 'bgred' : ''} onClick={selectLSQt(10)}>10%</a>
                        </li>
                        <li key="LSli25">
                          <a href="#!" className={selectedOptionLS === 25 ? 'bgred' : ''} onClick={selectLSQt(25)}>25%</a>
                        </li>
                        <li key="LSli50">
                          <a href="#!" className={selectedOptionLS === 50 ? 'bgred' : ''} onClick={selectLSQt(50)}>50%</a>
                        </li>
                        <li key="LSli100">
                          <a href="#!" className={selectedOptionLS === 100 ? 'bgred' : ''} onClick={selectLSQt(100)}>100%</a>
                        </li>
                        <li key="LSliCustom">
                          <Field as="select" name="qt" id="qtLS" onChange={changeLSQt} >
                            <option value="-1" keu="-1">% Amount</option>
                            <option value="0" keu="0">Fixed</option>
                            {options.length > 0 && options.map((item) =>
                              <option value={item.value} keu={item.value}>{item.label}</option>
                            )}
                          </Field>
                          <ErrorMessage
                            id="qtLSerror"
                            name="qt"
                            component="div"
                            className="alert alert-danger"
                          />
                        </li>
                      </ul>
                      {viewLS && (
                        <div className="input-group" style={{ "margin-bottom": "10px" }}>
                          <Field
                            id="fixedamountLS"
                            name="fixedamount"
                            type="text"
                            className="form-control"
                            placeholder="Amount"
                            required
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">{pairData.base_asset}</span>
                          </div>
                        </div>
                      )}
                      <button className="btn sell" style={{ "margin-top": "0px" }}>
                        Sell <span class="badge badge-pill badge-light" title="Active accounts">{props.Accounts}</span>
                      </button>
                    </Form>
                  </Formik>
                </div>
              </div>
            </Tab>

          </Tabs>
        </div>
      </div >
    </>
  );

}
