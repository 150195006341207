import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import logger from 'redux-logger';
import { ErrorLogger } from './middlewares/ErrorLogger';

import { authApi } from './services/auth';
import authReducer from './slices/auth';
import { userApi } from './services/user';
import pairReducer from './slices/pair';
import exchangesReducer from './slices/exchanges';
import orderReducer from './slices/order';
import accountReducer from './slices/account';
import { binanceApi } from './services/binance';
import { kucoinApi } from './services/kucoin';
import { therocktradingApi } from './services/therocktrading';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [binanceApi.reducerPath]: binanceApi.reducer,
    [kucoinApi.reducerPath]: kucoinApi.reducer,
    [therocktradingApi.reducerPath]: therocktradingApi.reducer,
    pair: pairReducer,
    exchanges: exchangesReducer,
    order: orderReducer,
    account: accountReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(logger)
    .concat(ErrorLogger)
    .concat(authApi.middleware)
    .concat(userApi.middleware)
    .concat(binanceApi.middleware)
    .concat(kucoinApi.middleware)
    .concat(therocktradingApi.middleware)
})

setupListeners(store.dispatch)