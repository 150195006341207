import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useWebSocket from 'react-use-websocket';
import { selectPairData } from '../slices/pair';
import { selectExchanges, getExchangeMessage } from '../slices/exchanges';
import { generateId } from '../helpers/utils';
import BigNumber from 'bignumber.js';

export default function MarketHistory() {
  const pairData = useSelector(selectPairData);
  const exchanges = useSelector(selectExchanges);
  const currentExchange = pairData.exchange.toLowerCase();
  const currentPair = pairData.pair;
  const [tradeHistory, setTradeHistory] = useState([]);

  const ws = {
    binance: useWebSocket(exchanges.binance.url, {
      share: true,
      filter: (messageEvent) => {
        const message = JSON.parse(messageEvent.data);
        return getExchangeMessage.binance.trades.messageFilter(message, currentPair);
      }
    }),
    kucoin: useWebSocket(exchanges.kucoin.url, {
      share: true,
      filter: (messageEvent) => {
        const message = JSON.parse(messageEvent.data);
        return getExchangeMessage.kucoin.trades.messageFilter(message, currentPair);
      }
    }),
  }

  const currentSendJsonMessage = ws[currentExchange].sendJsonMessage;
  const currentLastJsonMessage = ws[currentExchange].lastJsonMessage;

  useEffect(() => {
    const subscribePayload = getExchangeMessage[currentExchange].trades.subscribePayload(generateId(), [getExchangeMessage[currentExchange].trades.channel(currentPair)]);
    const unsubscribePayload = getExchangeMessage[currentExchange].trades.unsubscribePayload(generateId(), [getExchangeMessage[currentExchange].trades.channel(currentPair)]);

    currentSendJsonMessage(subscribePayload);
    return () => {
      currentSendJsonMessage(unsubscribePayload);
      setTradeHistory([]);
    }
  }, [currentSendJsonMessage, currentExchange, currentPair]);

  useEffect(() => {
    if (currentLastJsonMessage !== null) {
      const data = getExchangeMessage[currentExchange].trades.messageFormatter(currentLastJsonMessage);
      setTradeHistory((tradeList) => {
        const newTradeList = [...tradeList];
        newTradeList.unshift(data);
        if (newTradeList.length > 100) {
          newTradeList.pop();
        }
        return newTradeList;
      });
    }
  }, [currentLastJsonMessage, currentExchange]);

  return (
    <>
      <div className="market-history">
        <h2 className="heading">Recent Trades</h2>
        <table className="table" style={{ "marginBottom": "0px", "lineHeight": "1.3" }}>
          <thead>
            <tr>
              <th>Price({pairData.quote_asset})</th>
              <th>Amount({pairData.base_asset})</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody style={{ "height": "720px" }}>
            {tradeHistory.map((trade, index) => (
              <tr key={index + "-" + trade.time}>
                <td className={trade.color}>{trade.price}</td>
                <td>{new BigNumber(trade.amount).toFormat(null, 1)}</td>
                <td>{trade.time}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
