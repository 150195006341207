import React from 'react';
import { useSelector } from 'react-redux';
import { selectActivePair, selectPairData } from '../slices/pair';
import Error from './Error';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';

export default function TradingChart() {
  const activePair = useSelector(selectActivePair);
  const pairData = useSelector(selectPairData);

  if (activePair == null) return <Error message="No pair selected" />;

  return (
    <>
      <div className="main-chart mb15">
        <TradingViewWidget
          symbol={pairData.exchange + ":" + pairData.base_asset + pairData.quote_asset}
          theme={Themes.DARK}
          interval="1"
          locale="en"
          autosize
        />
      </div>
    </>
  );
}
