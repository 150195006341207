import React from 'react';
import { useSelector } from 'react-redux';
import { selectActivePair, selectPairData } from '../slices/pair';

import Header from '../components/Header';
import HistoryOrder from '../components/HistoryOrder';
import MarketHistory from '../components/MarketHistory';
import MarketHistoryTRT from '../components/therocktrading/MarketHistory';
import MarketNews from '../components/MarketNews';
import SubscribedPairsList from '../components/SubscribedPairsList';
import MarketTrade from '../components/MarketTrade';
import OrderBook from '../components/OrderBook';
import OrderBookTRT from '../components/therocktrading/OrderBook';
import TradingChart from '../components/TradingChart';
import { useGetAccountsQuery } from '../services/user';


export default function Trade() {
  const activePair = useSelector(selectActivePair);
  const currentPairData = useSelector(selectPairData);

  const { data = [], isLoading } = useGetAccountsQuery();
  let EnabledAccount = data.filter(el => el.active === 1).length

  return (
    <>
      <Header />

      <div className="container-fluid mtb15 no-fluid">
        <div className="row sm-gutters">
          <div className="col-sm-12 col-md-2">
            <SubscribedPairsList />
          </div>

          <div className="col-sm-12 col-md-6">
            <TradingChart />
            <MarketTrade Accounts={EnabledAccount} />
          </div>
          <div className="col-md-2">
            {activePair === null ? (
              "" /*<Loading />*/
            ) : (
              {
                'therocktrading': <OrderBookTRT />
              }[currentPairData.exchange.toLowerCase()] || <OrderBook />
            )}
          </div>
          <div className="col-md-2">
            {activePair === null ? (
              "" /*<Loading />*/
            ) : (
              {
                'therocktrading': <MarketHistoryTRT />
              }[currentPairData.exchange.toLowerCase()] || <MarketHistory />
            )}
          </div>
          <div className="col-md-2">
            <MarketNews />
          </div>
          <div className="col-md-10">
            <HistoryOrder />
          </div>
        </div>
      </div>
    </>
  );
}
