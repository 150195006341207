import React from 'react';
import '../assets/scss/module/_market-history.scss';
import { Tabs, Tab } from 'react-bootstrap';
import { useGetOrdersQuery } from '../services/user';

export default function HistoryOrder() {
  const { data = [] } = useGetOrdersQuery();

  const slice = data

  return (
    <>
      <div className="market-trade">
        <Tabs defaultActiveKey="open-orders">
          <Tab eventKey="open-orders" className='order-history-tab' title="Last Orders">
            <table className="table order-history">
              <thead>
                <tr>
                  <th>Created</th>
                  <th>Exchange</th>
                  <th>Asset</th>
                  <th>Type</th>
                  <th>Price</th>
                  <th>Amount</th>
                  <th>Orders</th>
                  <th>Status</th>

                </tr>
              </thead>
              <tbody>
                {slice.map((order, index) => (
                  <tr key={order.id}>
                    <td>{order.timestamp}</td>
                    <td>{order.exchange}</td>
                    <td>{order.asset}</td>
                    <td>{order.type}</td>
                    <td>{order.price}</td>
                    <td>{order.amount}
                      {{
                        perc: " %",
                        fixed: ""
                      }[order.amount_type]}
                    </td>
                    <td>{order.subcount}</td>
                    <td>{order.status}</td>

                  </tr>
                ))}
              </tbody>
            </table>
          </Tab>
        </Tabs>
      </div >
    </>
  );
}
