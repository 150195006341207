import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveAccount, selectActiveAccount } from '../slices/account';
import { userApi } from '../services/user';
import {
  useGetAccountQuery,
  useGetAccountBalanceQuery,
  useGetAccountLiveBalanceQuery,
  useUpdateAccountMutation,
  useDeleteAccountMutation,
  useGetAccountApiKeysQuery,
  useAddAccountApiKeysMutation,
  useDeleteAccountApiKeysMutation
} from '../services/user';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from 'react-hot-toast';

import Loading from './Loading';

export default function AccountBalance() {
  const [showEdit, setShowEdit] = useState(false);
  const [viewExchangeApiKey, setViewExchangeApiKey] = useState(false);

  const dispatch = useDispatch();
  const activeAccount = useSelector(selectActiveAccount);
  const cAccount = activeAccount;
  const [skipLoadLiveBalance, setSkipLoadLiveBalance] = useState(true);
  const { data: account, isLoading: accountIsLoading } = useGetAccountQuery(cAccount);
  const { data: accountBalance = [], isLoading: accountBalanceIsLoading } = useGetAccountBalanceQuery(cAccount);
  const { data: accountLiveBalance = [], isSuccess: accountLiveBalanceIsSuccess, isUninitialized: accountLiveBalanceIsUninitialized } = useGetAccountLiveBalanceQuery(cAccount, { skip: skipLoadLiveBalance });
  const { data: accountApiKeys = [] } = useGetAccountApiKeysQuery(cAccount);

  useEffect(() => {
    // execute this
    setShowEdit(false)
  }, [cAccount]); // when hotContact changes


  const [PatchAccount] = useUpdateAccountMutation();
  const [DeleteAccount] = useDeleteAccountMutation();
  const [DeleteApiKey] = useDeleteAccountApiKeysMutation();
  const [AddApiKey] = useAddAccountApiKeysMutation();

  if (accountLiveBalanceIsSuccess) {
    dispatch(
      userApi.util.updateQueryData('getAccountBalance', cAccount, (accountBalance) => {
        Object.assign(accountBalance, accountLiveBalance);
      })
    );
    setSkipLoadLiveBalance(true);
  }

  const handleShowEdit = () => {
    setShowEdit(prev => !prev);
    setViewExchangeApiKey(0);
  }

  const handleAccountUpdate = async (formValue) => {
    try {
      const { accountNickname: nickname, accountOrderId: order_id } = formValue;
      const patchProfile = await PatchAccount({ aid: cAccount, nickname, order_id }).unwrap();
      if (patchProfile === "Updated") {
        toast.success("User updated");
        //dispatch(selectUser({ twofactor: true }));
      } else {
        toast.error("Operation failed");
      }
    }
    catch (e) {
      console.log("Error", e)
    }
  }

  const handleAddAccountApiKey = async (formValue) => {
    try {
      const newApiKey = JSON.stringify({ aid: cAccount, eid: formValue.heid, apikey: formValue.apikey, apisecret: formValue.apisecret, passphrase: formValue.passphrase });
      const AccountApiKey = await AddApiKey(newApiKey).unwrap();
      if (AccountApiKey === "Created") {
        toast.success("Apikey added");
        dispatch(userApi.util.invalidateTags(['AccountApiKeys']))
      } else {
        toast.error("Operation failed");
      }
    }
    catch (e) {
      console.log("Error", e)
    }
  }


  const handleAccountDelete = async (values) => {
    try {
      if (window.confirm("Are you sure to delete account " + values.nickname + "?")) {
        const delAccount = await DeleteAccount(values.id).unwrap();
        if (delAccount === "Deleted") {
          toast.success("Account deleted");
          dispatch(setActiveAccount(null))
        } else {
          toast.error("Operation failed");
        }
      }
    }
    catch (e) {
      console.log("Error", e)
    }
  }

  const handleAccountApiKeyDelete = async (values) => {
    try {
      if (window.confirm("Are you sure to delete " + values.exchange_name + " Api Key?")) {
        const delAccountApiKey = await DeleteApiKey(JSON.stringify({ aid: values.account_id, akid: values.api_id })).unwrap();
        if (delAccountApiKey === "Deleted") {
          toast.success("Apikey deleted");
          dispatch(userApi.util.invalidateTags(['AccountApiKeys']))
        } else {
          toast.error("Operation failed");
        }
      }
    }
    catch (e) {
      console.log("Error", e)
    }
  }


  if (accountIsLoading || accountBalanceIsLoading) return <Loading />;

  return (
    <>
      <div className="row">
        <div className="col-md-10">
          <span className="card-title">Account</span> <span className='red fsize16'>{account.nickname}</span>
        </div>

        <div className="col-md-2">
          <input className="btn btn-primary btn-sm" type="button" value="Edit" onClick={handleShowEdit} />
          &nbsp;
          <button className="btn btn-info btn-sm" onClick={() => setSkipLoadLiveBalance(false)} disabled={!accountLiveBalanceIsUninitialized}><i class="icon ion-md-refresh"></i> Update</button>
        </div>
      </div>

      {showEdit ? (
        <div className="EditorDiv">
          <br />

          <div className="card">
            <div className="card-body">
              <h5 className="card-title">General information</h5>
              <Formik
                initialValues={{ aid: account.id, accountNickname: account.nickname, accountOrderId: account.order_id }}
                validationSchema={""}
                onSubmit={handleAccountUpdate}
              >
                <Form>
                  <div className="form-row">
                    <div className="col-md-3">
                      <label htmlFor="accountNickname">
                        Nickname
                      </label>
                      <Field
                        id="accountNickname"
                        name="accountNickname"
                        type="text"
                        className="form-control"
                        placeholder="Nickname"
                      />
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="accountOrderId">
                        Order
                      </label>
                      <Field
                        id="accountOrderId"
                        name="accountOrderId"
                        type="number"
                        min="0"
                        max="1000"
                        className="form-control"
                        placeholder="Order"
                      />
                    </div>
                    <div className="col-md-3">
                    </div>
                    <div className='col-md-3 m25'>

                      <input className="btn btn-primary" type="submit" value="Update" />
                      &nbsp;
                      <input className="btn btn-danger" type="button" onClick={() => handleAccountDelete(account)} value="Delete" />
                    </div>
                  </div>
                </Form>
              </Formik>

            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Create API Key</h5>
              <div className="form-row">
                <div className="col-md-3">
                  <label htmlFor="generateKey">
                    Exchange
                  </label>
                  <select
                    className='form-control'
                    onChange={e => setViewExchangeApiKey(e.target.value)}
                    name="exchange_id">
                    <option value="0">Select an Exchange</option>
                    <option value="1">Binance</option>
                    <option value="2">KuCoin</option>
                    <option value="3">TheRockTrading</option>
                  </select>
                </div>
              </div>
              {viewExchangeApiKey === "1" && (
                <Formik name="formikBinance" id="formikBinance"
                  initialValues={{ heid: 1, apikey: "", apisecret: "", passphrase: "" }}
                  validationSchema={
                    Yup.object().shape({
                      apikey: Yup.string().required("This field is required!"),
                      apisecret: Yup.string().required("This field is required!"),
                    })
                  }
                  onSubmit={handleAddAccountApiKey}
                >
                  <Form name="formBinance" id="formBinance">
                    <Field id="hiddenExchangeIdBinance" type="hidden" name="heid" />
                    <div className='form-row m15' name="BinanceApi">
                      <div className="col-md-3">
                        <label htmlFor="apikeyBinance">
                          Api-Key
                        </label>
                        <Field
                          id="apikeyBinance"
                          name="apikey"
                          type="text"
                          className="form-control"
                          placeholder="Api Key"
                        />
                        <ErrorMessage
                          id="apikeyBinanceError"
                          name="apikey"
                          component="div"
                          className="alert alert-danger"
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="apisecret">
                          Api-Secret B
                        </label>
                        <Field
                          id="apisecretBinance"
                          name="apisecret"
                          type="password"
                          className="form-control"
                          placeholder="Secret code"
                        />
                        <ErrorMessage
                          id="apisecretBinanceError"
                          name="apisecret"
                          component="div"
                          className="alert alert-danger"
                        />
                      </div>
                      <div className="col-md-3">
                        <Field
                          id="passphraseBinance"
                          name="passphrase"
                          type="hidden"
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="submitButtonBinance">
                          &nbsp;
                        </label>
                        <input
                          id="submitButtonBinance"
                          name="submitButton"
                          type="submit"
                          className="form-control" style={{ "background-color": "#007bff" }}
                          value="Add API Key"
                        />
                      </div>
                    </div>
                  </Form>
                </Formik>
              )}
              {viewExchangeApiKey === "2" && (

                <Formik name="formikKucoin" id="formikKucoin"
                  initialValues={{ heid: 2, apikey: "", apisecret: "", passphrase: "" }}
                  validationSchema={
                    Yup.object().shape({
                      apikey: Yup.string().required("This field is required!"),
                      apisecret: Yup.string().required("This field is required!"),
                      passphrase: Yup.string().required("This field is required!"),
                    })
                  }
                  onSubmit={handleAddAccountApiKey}
                >
                  <Form name="formKucoin" id="formKucoin">
                    <Field id="hiddenExchangeIdKucoin" type="hidden" name="heid" />
                    <div className='form-row m15' name="KucoinApi">
                      <div className="col-md-3">
                        <label htmlFor="apikeyKucoin">
                          Api-Key
                        </label>
                        <Field
                          id="apikeyKucoin"
                          name="apikey"
                          type="text"
                          className="form-control"
                          placeholder="Api Key"
                        />
                        <ErrorMessage
                          id="apikeyKucoinError"
                          name="apikey"
                          component="div"
                          className="alert alert-danger"
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="apisecret">
                          Api-Secret
                        </label>
                        <Field
                          id="apisecretKucoin"
                          name="apisecret"
                          type="password"
                          className="form-control"
                          placeholder="Secret code"
                        />
                        <ErrorMessage
                          id="apisecretKucoinError"
                          name="apisecret"
                          component="div"
                          className="alert alert-danger"
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="passphrase">
                          Passphrase
                        </label>
                        <Field
                          id="passphraseKucoin"
                          name="passphrase"
                          type="password"
                          className="form-control"
                          placeholder="Passphrase"
                        />
                        <ErrorMessage
                          id="passphraseKucoinError"
                          name="passphrase"
                          component="div"
                          className="alert alert-danger"
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="submitButtonKucoin">
                          &nbsp;
                        </label>
                        <input
                          id="submitButtonKucoin"
                          name="submitButton"
                          type="submit"
                          className="form-control" style={{ "background-color": "#007bff" }}
                          value="Add API Key"
                        />
                      </div>
                    </div>
                  </Form>
                </Formik>
              )}
              {viewExchangeApiKey === "3" && (

                <Formik name="formikTRT" id="formikTRT"
                  initialValues={{ heid: 3, apikey: "", apisecret: "", passphrase: "" }}
                  validationSchema={
                    Yup.object().shape({
                      apikey: Yup.string().required("This field is required!"),
                      apisecret: Yup.string().required("This field is required!")
                    })
                  }
                  onSubmit={handleAddAccountApiKey}
                >
                  <Form name="formTRT" id="formTRT">
                    <Field id="hiddenExchangeIdTRT" type="hidden" name="heid" />
                    <div className='form-row m15' name="TRTApi">
                      <div className="col-md-3">
                        <label htmlFor="apikeyTRT">
                          Api-Key
                        </label>
                        <Field
                          id="apikeyTRT"
                          name="apikey"
                          type="text"
                          className="form-control"
                          placeholder="Api Key"
                        />
                        <ErrorMessage
                          id="apikeyTRTError"
                          name="apikey"
                          component="div"
                          className="alert alert-danger"
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="apisecret">
                          Api-Secret
                        </label>
                        <Field
                          id="apisecretTRT"
                          name="apisecret"
                          type="password"
                          className="form-control"
                          placeholder="Secret code"
                        />
                        <ErrorMessage
                          id="apisecretTRTError"
                          name="apisecret"
                          component="div"
                          className="alert alert-danger"
                        />
                      </div>
                      <div className="col-md-3">
                        <Field
                          id="passphraseBinance"
                          name="passphrase"
                          type="hidden"
                        />
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="submitButtonTRT">
                          &nbsp;
                        </label>
                        <input
                          id="submitButtonTRT"
                          name="submitButton"
                          type="submit"
                          className="form-control" style={{ "background-color": "#007bff" }}
                          value="Add API Key"
                        />
                      </div>
                    </div>
                  </Form>
                </Formik>
              )}
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Your API Keys</h5>
              <div className="wallet-history">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Exchange</th>
                      <th>Key</th>
                      <th>Secret</th>
                      <th>Passphrase</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accountApiKeys.map((apikey) => (
                      <tr>
                        <td>{apikey.exchange_name}</td>
                        <td>{apikey.api}</td>
                        <td>{apikey.secret}</td>
                        <td>{apikey.passphrase}</td>
                        <td>

                          <i className="icon ion-md-trash" onClick={() => handleAccountApiKeyDelete(apikey)}></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )
        : ''
      }

      {showEdit === false ? (
        <div className="table-balance-div">
          <h5 className="card-title">
            Balance
          </h5>

          <table className="table table-accounts-list">
            <thead>
              <tr>
                <th>Exchange</th>
                <th>Asset</th>
                <th align="right">Amount</th>
                <th align="right">Locked</th>
                <th align="right">Updated</th>
                {/*< th > Action</th>*/}
              </tr>
            </thead>
            <tbody>
              {accountBalance.map((balance, index) => (
                <tr>
                  <td>{balance.exchange}</td>
                  <td>{balance.asset}</td>
                  <td>{balance.amount}</td>
                  <td>{balance.locked}</td>
                  <td>{balance.updated}</td>
                  {/*<td>
                  <input className="btn btn-primary btn-sm"
                    type="button"
                    value="Withdraw"></input>
                  </td>*/}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : ""}
    </>
  );
}
