import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseUrl = '/api/public/exchanges/therocktrading'

export const therocktradingApi = createApi({
  reducerPath: 'therocktradingApi',
  baseQuery: fetchBaseQuery({
    baseUrl, prepareHeaders: (headers, { getState }) => {
      if (getState().auth.isAuthenticated) {
        const token = JSON.parse(localStorage.getItem('kniteToken')).token;
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getAllTickers: builder.query({
      query: () => ({
        url: '/all_tickers',
        method: 'GET',
      }),
      transformResponse: (response) => response.reduce((accumulator, currentValue) => {
        accumulator[currentValue.symbolName] = {
          exchange: "TheRockTrading",
          exchangeId: 3,
          symbol: currentValue.symbolName,
          baseAsset: currentValue.baseCurrency,
          quoteAsset: currentValue.quoteCurrency,
          lastPrice: currentValue.last,
          change: 0,
          high: currentValue.high,
          low: currentValue.low,
          volume: currentValue.vol,
          favorite: false,
          pairId: null
        }
        return accumulator;
      }, {})
    }),
    getTicker: builder.query({
      query: (pair) => ({
        url: '/get_ticker/' + pair,
        method: 'GET'
      }),
    }),
    getTrades: builder.query({
      query: (pair) => ({
        url: '/get_trades/' + pair,
        method: 'GET'
      }),
    }),
    getOrderBook: builder.query({
      query: (pair) => ({
        url: '/get_orderbook/' + pair,
        method: 'GET'
      }),
    }),
  })
})

export const { useGetAllTickersQuery, useGetTickerQuery, useGetTradesQuery, useGetOrderBookQuery } = therocktradingApi
