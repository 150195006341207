import React from 'react';
import Header from '../components/Header';
import AccountsList from '../components/AccountsList';
import AccountBalance from '../components/AccountBalance';
import AccountsBalance from '../components/AccountsBalance';
import { useSelector } from "react-redux";
import { selectActiveAccount } from '../slices/account';


export default function Accounts() {

  const activeAccount = useSelector(selectActiveAccount);

  return (
    <>
      <Header />
      <div className="settings mtb15">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              <AccountsList />
            </div>
            <div className="col-md-8">
              {(activeAccount != null && activeAccount >= 0) && (
                <AccountBalance />
              )}
              {(activeAccount != null && activeAccount < 0) && (
                <AccountsBalance />
              )}

            </div>
          </div>

        </div>
      </div>
      <div className="container-fluid mtb15 no-fluid">
        <div className="row sm-gutters">
          <div className="col-md-12">

          </div>
        </div>
      </div>
    </>
  );
}
