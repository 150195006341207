import React, { useCallback } from "react";
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useGetAccountsQuery } from '../services/user';

import { selectUser } from '../slices/auth';
import { logout } from "../slices/auth";

export default function Header() {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data = [], isLoading } = useGetAccountsQuery();
  let EnabledAccount = data.filter(el => el.active === 1).length

  const logOut = useCallback(() => {
    dispatch(logout());
    localStorage.removeItem("kniteToken");
    navigate("/login");
  }, [dispatch, navigate]);


  return (
    <>
      <header className="light-bb">
        {user && (
          <Navbar expand="lg">
            <Link className="navbar-brand" to="/">
              <img src={'img/logo-light.svg'} alt="logo" />
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="navbar-nav mr-auto">
                <Link to="/" className="nav-link">Dashboard</Link>
                <Link to="/markets" className="nav-link active">Markets</Link>
                <Link to="/orders" className="nav-link">Orders</Link>
                <Link to="/accounts" className="nav-link">Accounts <span class="badge badge-pill badge-primary" title="Active accounts">{EnabledAccount}</span></Link>
              </Nav>
              <Nav className="navbar-nav ml-auto">
                <Dropdown className="header-img-icon">
                  <Dropdown.Toggle variant="default">
                    <img src={'img/avatar.svg'} alt="avatar" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="dropdown-header d-flex flex-column align-items-center">
                      <div className="figure mb-3">
                        <img src={'img/avatar.svg'} alt="" />
                      </div>
                      <div className="info text-center">
                        <p className="name font-weight-bold mb-0">{user.firstname} {user.lastname}</p>
                        <p className="email text-muted mb-3">
                          {user.email}
                        </p>
                      </div>
                    </div>
                    <div className="dropdown-body">
                      <ul className="profile-nav">
                        <li className="nav-item">
                          <Link to="/profile" className="nav-link">
                            <i className="icon ion-md-person"></i>
                            <span>Profile</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/profile#settings" className="nav-link">
                            <i className="icon ion-md-settings"></i>
                            <span>Settings</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/login" className="nav-link red" onClick={logOut}>
                            <i className="icon ion-md-power"></i>
                            <span>Log Out</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        )}
      </header>
    </>
  );
}
