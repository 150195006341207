import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useWebSocket from 'react-use-websocket';
import { selectPairData } from '../slices/pair';
import { selectExchanges, getExchangeMessage } from '../slices/exchanges';
import { generateId } from '../helpers/utils';
import BigNumber from 'bignumber.js';

export default function OrderBook() {
  const pairData = useSelector(selectPairData);
  const exchanges = useSelector(selectExchanges);
  const currentExchange = pairData.exchange.toLowerCase();
  const currentPair = pairData.pair;
  const [orderBook, setOrderBook] = useState({ bids: [], asks: [] });

  const ws = {
    binance: useWebSocket(exchanges.binance.url, {
      share: true,
      filter: (messageEvent) => {
        const message = JSON.parse(messageEvent.data);
        return getExchangeMessage.binance.orderbook.messageFilter(message, currentPair);
      }
    }),
    kucoin: useWebSocket(exchanges.kucoin.url, {
      share: true,
      filter: (messageEvent) => {
        const message = JSON.parse(messageEvent.data);
        return getExchangeMessage.kucoin.orderbook.messageFilter(message, currentPair);
      }
    }),
  }

  const currentSendJsonMessage = ws[currentExchange].sendJsonMessage;
  const currentLastJsonMessage = ws[currentExchange].lastJsonMessage;

  useEffect(() => {
    const subscribePayload = getExchangeMessage[currentExchange].orderbook.subscribePayload(generateId(), [getExchangeMessage[currentExchange].orderbook.channel(currentPair)]);
    const unsubscribePayload = getExchangeMessage[currentExchange].orderbook.unsubscribePayload(generateId(), [getExchangeMessage[currentExchange].orderbook.channel(currentPair)]);

    currentSendJsonMessage(subscribePayload);
    return () => {
      currentSendJsonMessage(unsubscribePayload);
      setOrderBook({ bids: [], asks: [] });
    }
  }, [currentSendJsonMessage, currentExchange, currentPair]);

  useEffect(() => {
    if (currentLastJsonMessage !== null) {
      const data = getExchangeMessage[currentExchange].orderbook.messageFormatter(currentLastJsonMessage);
      setOrderBook(data);
    }
  }, [currentLastJsonMessage, currentExchange]);


  let bids = [];
  let asks = [];
  let numRowsBid = Math.min(18, orderBook.bids.length);
  let numRowsAsk = Math.min(18, orderBook.asks.length);
  let maxBid = BigNumber.maximum(...orderBook.bids.map(bid => bid[0]));
  let minAsk = BigNumber.minimum(...orderBook.asks.map(ask => ask[0]));
  let minBid = new BigNumber(maxBid).minus(BigNumber.minimum(...orderBook.bids.map(bid => bid[0])));
  let maxAsk = new BigNumber(minAsk).minus(BigNumber.maximum(...orderBook.asks.map(ask => ask[0])));
  let totalBidsAmount = new BigNumber(0);
  let totalAsksAmount = new BigNumber(0);
  for (var i = 0; i < numRowsBid; i++) {
    let bidWidth = new BigNumber(maxBid).minus(orderBook.bids[i][0]).div(minBid).multipliedBy(100).toFormat(0);
    totalBidsAmount = totalBidsAmount.plus(new BigNumber(orderBook.bids[i][1]));
    bids.push({
      key: "bid-" + i + "-" + orderBook.bids[i][0] + "-" + orderBook.bids[i][1],
      price: new BigNumber(orderBook.bids[i][0]).toFormat(null, 1),
      quantity: new BigNumber(orderBook.bids[i][1]).toFormat(null, 1),
      total: totalBidsAmount.toFormat(null, 1),
      width: bidWidth,
      style: ".bid-" + i + ":after {width:" + bidWidth + "%}",
      class: "bid-" + i
    });
  }
  for (var ii = 0; ii < numRowsAsk; ii++) {
    let askWidth = new BigNumber(minAsk).minus(orderBook.asks[ii][0]).div(maxAsk).multipliedBy(100).toFormat(0);
    totalAsksAmount = totalAsksAmount.plus(new BigNumber(orderBook.asks[ii][1]));
    asks.unshift({
      key: "ask-" + ii + "-" + orderBook.asks[ii][0] + "-" + orderBook.asks[ii][1],
      price: new BigNumber(orderBook.asks[ii][0]).toFormat(null, 1),
      quantity: new BigNumber(orderBook.asks[ii][1]).toFormat(null, 1),
      total: totalAsksAmount.toFormat(null, 1),
      width: askWidth,
      style: ".ask-" + ii + ":after {width:" + askWidth + "%} ",
      class: "ask-" + ii
    });
  }

  //{{width: `${new BigNumber(props.diff).minus(props.ba[0]).div(props.max).multipliedBy(100).toFormat(2)}%`}}

  return (
    <>
      {asks.map((ask) => (<style key={"style-" + ask.key}>{ask.style}</style>))}
      {bids.map((bid) => (<style key={"style-" + bid.key}>{bid.style}</style>))}
      <div className="order-book">
        <h2 className="heading">Order Book</h2>
        <table className="table" style={{ "marginBottom": "0px", "lineHeight": "1.3" }}>
          <thead>
            <tr>
              <th>Price({pairData.quote_asset})</th>
              <th>Amount({pairData.base_asset})</th>
              <th>Total({pairData.base_asset})</th>
            </tr>
          </thead>
          <tbody style={{ "height": "360px" }}>
            {asks.map((ask) => (
              <tr className={"red-bg " + ask.class} key={ask.key} id={ask.key}>
                <td className="red">{ask.price}</td>
                <td>{ask.quantity}</td>
                <td>{ask.total}</td>
              </tr>
            ))}
          </tbody>
          <tbody style={{ "height": "360px" }}>
            {bids.map((bid) => (
              <tr className={"green-bg " + bid.class} key={bid.key} id={bid.key}>
                <td className="green">{bid.price}</td>
                <td>{bid.quantity}</td>
                <td>{bid.total}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
